import { fetchServer } from '../../../net/commonnet';
import { PaymentInfoResponse, UserInfo } from './data';

const API = `${process.env.REACT_APP_BACKEND_URL}/api`;

const ENDPOINTS = {
  ACTIVATE_GIFT_CARD: `${API}/payments/gift-cards/activate`,
  EMAIL_REGISTER: `${API}/auth/register`,
  EMAIL_LOGIN: `${API}/auth/login`,
  SOCIAL_REGISTER: {
    facebook: `${API}/soc-auth/register/facebook`,
  },
  SOCIAL_LOGIN: {
    facebook: `${API}/soc-auth/login/facebook`,
  },
  USER_PURCHASES: `${API}/user/purchase`,
};

export async function register(
  email: string,
  password: string,
): Promise<UserInfo> {
  const result = await fetchServer<UserInfo>(ENDPOINTS.EMAIL_REGISTER, 'POST', {
    email,
    password,
    confirm: password,
  });

  if (result.error.code === 0) {
    localStorage.setItem('auth_token', result.response.auth_token);
    return Promise.resolve(result.response);
  } else if (result.error.code === 409) {
    return login(email, password);
  } else {
    return Promise.reject(
      result.error || {
        debug_text: 'Unknown server error',
      },
    );
  }
}

export async function login(
  email: string,
  password: string,
): Promise<UserInfo> {
  const result = await fetchServer<UserInfo>(ENDPOINTS.EMAIL_LOGIN, 'POST', {
    email,
    password,
  });

  if (result.error.code === 0) {
    localStorage.setItem('auth_token', result.response.auth_token);
    return Promise.resolve(result.response);
  } else {
    return Promise.reject(
      result.error || {
        debug_text: 'Unknown server error',
      },
    );
  }
}

export async function socialLogin(
  userId: string,
  platform: 'facebook',
): Promise<UserInfo> {
  const result = await fetchServer<UserInfo>(
    ENDPOINTS.SOCIAL_LOGIN[platform],
    'POST',
    {
      provider_user_id: userId,
    },
  );

  if (result.error.code === 0) {
    localStorage.setItem('auth_token', result.response.auth_token);
    return Promise.resolve(result.response);
  } else {
    return Promise.reject(
      result.error || {
        debug_text: 'Unknown server error',
      },
    );
  }
}

export async function socialRegister(
  firstName: string,
  lastName: string,
  email: string,
  userId: string,
  accessToken: string,
  platform: 'facebook',
): Promise<UserInfo> {
  return fetchServer<UserInfo>(ENDPOINTS.SOCIAL_REGISTER[platform], 'POST', {
    first_name: firstName,
    last_name: lastName,
    email: email,
    provider_user_id: userId,
    token: {
      key: accessToken,
    },
  })
    .then(() => {
      return socialLogin(userId, platform);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function checkSubscription(): Promise<boolean> {
  const result = await fetchServer<PaymentInfoResponse>(
    ENDPOINTS.USER_PURCHASES,
  );

  if (result.error.code === 0) {
    return Promise.resolve(result.response.purchase?.is_active || false);
  } else {
    return Promise.reject(result.error);
  }
}

export async function activateGiftCode(code: string): Promise<boolean> {
  const result = await fetchServer<PaymentInfoResponse>(
    ENDPOINTS.ACTIVATE_GIFT_CARD,
    'POST',
    {
      gift_code: code,
    },
  );

  if (result.error.code === 0) {
    return Promise.resolve(result.response.purchase?.is_active || false);
  } else {
    return Promise.reject(result.error);
  }
}
