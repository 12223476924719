export interface ResponseError {
  code: number;
  description: string;
  debug_text: string;
}

export interface ServerResponse<T> {
  response: T;
  error: ResponseError;
}

const getToken = (): string | null => localStorage.getItem('auth_token');

export async function fetchServer<T>(
  endpoint: string,
  method: 'GET' | 'POST' = 'GET',
  body: object | null = null,
): Promise<ServerResponse<T>> {
  if (!navigator.onLine) {
    return Promise.reject('No internet connection');
  } else {
    return fetch(endpoint, {
      method: method,
      headers: {
        'Content-Type': body ? 'application/json' : '',
        Authorization: getToken() || '',
      },
      body: body && JSON.stringify(body),
    })
      .then((response) => {
        return response
          .json()
          .then((result) => {
            if (result.hasOwnProperty('response')) {
              return Promise.resolve(result);
            } else {
              return Promise.reject(result);
            }
          })
          .catch((reason) => {
            return Promise.reject(
              reason.debug_text || reason || 'Unknown error',
            );
          });
      })
      .catch((reason) => {
        return Promise.reject(reason.debug_text || reason || 'Unknown error');
      });
  }
}
