import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { appAnalytics } from '../analytics';
import AppRouter from '../approuter/AppRouter';
import { persistor, store, history } from '../bootstrap';

export const Website = () => {
  if (
    navigator.userAgent.indexOf('Instagram') !== -1 &&
    process.env.REACT_APP_FTP_REDIRECT_URL
  ) {
    const targetUrl = new URL(process.env.REACT_APP_FTP_REDIRECT_URL);
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.forEach((value, key) => {
      targetUrl.searchParams.append(key, value);
    });
    window.location.href = targetUrl.href;
  }

  if (!appAnalytics) {
    console.warn("Analytics haven't been initialized");
  }

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <AppRouter />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </>
  );
};
