import React from 'react';
import { appAnalytics } from '../../../analytics';
import AppStore from '../../../assets/loonaWeb/app_store.png';
import { Install } from '../../analytics';
import { getOneLink } from '../../utils';

export const AppStoreButton = () => {
  const handleClick = () => {
    appAnalytics.trackEvent(new Install());

    window.location.href = getOneLink({
      mediaSource: 'website',
      webDp:
        'https://apps.apple.com/us/app/loóna-calm-relax-and-sleep/id1465238901',
      campaign: 'website-ios',
      retargeting: 'true',
      androidUrl:
        'https://apps.apple.com/us/app/loóna-calm-relax-and-sleep/id1465238901',
    });
  };
  return (
    <a onClick={handleClick}>
      <img src={AppStore} alt="Download Loóna App" />
    </a>
  );
};
