import { ExperimentStarted } from './AllEvents';
import { Analytics } from './Analytics';
import { Event } from './Event';
import { Events } from './Events';
import { appAnalytics } from './index';

type GoogleAnalyticsEventInfo = {
  action: string;
  category?: string;
};

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const mappedEvents: Record<string, GoogleAnalyticsEventInfo> = {
  [Events.Install]: {
    action: 'generate_lead',
    category: 'engagement',
  },
  [Events.SignUp]: {
    action: 'sign_up',
    category: 'engagement',
  },
};

export class GoogleAnalytics extends Analytics {
  supportedEvents = [Events.Install, Events.SignUp];

  constructor() {
    super();

    activateOptimize();
  }

  trackEvent(event: Event) {
    const eventInfo = mappedEvents[event.name] || event.name;
    const params: any = event.params;

    window.dataLayer?.push({
      event: eventInfo.action,
      eventProps: {
        category: eventInfo.category,
        action: eventInfo.action,
        value: params,
      },
      ...params,
    });
  }
}

export const activateOptimize = () => {
  // @ts-ignore
  window.dataLayer?.push({ event: 'optimize.activate' });
};

export const initOptimize = () => {
  // @ts-ignore
  gtag('event', 'optimize.callback', {
    callback: (value: any, name: any) => {
      if (name && value) {
        appAnalytics.trackEvent(new ExperimentStarted(name, value));
      }
    },
  });
};

function gtag() {
  // @ts-ignore
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer?.push(arguments);
}
