import { push } from 'connected-react-router';

export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export function formatPrice(
  price?: number | string,
  currencySign?: string,
): string {
  const amount =
    typeof price === 'number' ? price : parseFloat(price || '0.00');
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencySign,
  }).format(amount);
}

// Returns date in format YYYY-MM-DD HH:mm:ss
export function getFormattedDate(date: Date = new Date()): string {
  const parts = Intl.DateTimeFormat('en', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  })
    .formatToParts(date)
    .reduce(
      (
        result: Map<Intl.DateTimeFormatPartTypes, string>,
        part: Intl.DateTimeFormatPart,
      ) => {
        result.set(part.type, part.value);
        return result;
      },
      new Map(),
    );

  const { year, month, day, hour, minute, second } = Object.fromEntries(parts);

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function pushWithSearchQuery(path: string) {
  return push({
    pathname: path,
    search: window.location.search,
  });
}

export function getQueryVariable(variable: string): string | undefined {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
}

function randomInt(bound: number): number {
  return Math.floor(Math.random() * bound);
}

export function generateCode(): string {
  const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const delimiter = '-';

  const chars: string[] = [];

  for (let i = 0; i < 6; i++) {
    chars[i] = alphabet[randomInt(alphabet.length - 1)];
  }

  chars.splice(3, 0, delimiter);
  return chars.join('');
}

export const getSubDomain = () => {
  const parsedData = location.hostname.split('.');

  if (parsedData.length >= 2) {
    return parsedData[0];
  } else {
    return undefined;
  }
};

export const isGiftFlow = (): boolean => {
  return window.location.pathname.split('/').includes('gift');
};

export const isCookiesAccepted = (): boolean => {
  return localStorage.getItem('cookies-accepted') === 'true';
};
