import React from 'react';
import BannerIcon from '../../../assets/banner/banner_loona_icon.png';
import { ReactComponent as StarsIcon } from '../../../assets/banner/stars.svg';
import styles from './styles.module.scss';
import { useBannerLogic } from './Banner.hook';

export const Banner = () => {
  const { isIosDevice, isBannerShown, onClickHandler, onClose } =
    useBannerLogic();

  return (
    <div
      className={`${styles.container} ${!isBannerShown ? styles.hidden : ''}`}
    >
      <img src={BannerIcon} className={styles.bannerIcon} alt="" />
      <span className={styles.closeIcon} onClick={onClose}>
        ×
      </span>
      <div className={styles.content}>
        <div className={styles.title}>Loóna: calm, relax and sleep</div>
        <div className={styles.author}>Developer</div>
        <StarsIcon className={styles.starsIcon} />
        <div className={styles.subtitle}>
          GET – {isIosDevice ? 'on the App Store' : 'on Google Play'}
        </div>
      </div>
      <button className={styles.button} onClick={onClickHandler}>
        View
      </button>
    </div>
  );
};
