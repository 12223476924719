export enum Events {
  GiftScreenOpened = 'viewGiftScreen',
  GiftProductSelected = 'continueGiftWelcome',
  GiftAmountSelected = 'continueGiftPlanSettings',
  GiftPurchaseSuccess = 'giftPurchase',
  RedeemScreenOpened = 'viewRedeemScreen',
  RedeemWelcomeContinue = 'continueRedeemWelcome',
  RedeemSignUp = 'signUp',
  RedeemGiftCodeActivated = 'ContinueGiftCode',
  RedeemAppContinue = 'ContinueRedeemCongratsScreen',

  PayPalClicked = 'clickPayPalButton',
  CardFormInputStarted = 'clickCreditCardInput',

  ExperimentStarted = 'experimentEntered',
  Install = 'clickInstall',
  SignUp = 'completeRegistration',
}
