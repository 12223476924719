import { fetchServer, ServerResponse } from '../../net/commonnet';

const API = process.env.REACT_APP_BACKEND_URL;

const ENDPOINTS = {
  REQUEST_PASSWORD_RESET: `${API}/api/auth/request-password-reset`,
  RESET_PASSWORD: `${API}/api/auth/password-reset`,
};

export async function requestPasswordReset(email: string): Promise<any> {
  return fetchServer<ServerResponse<object>>(
    ENDPOINTS.REQUEST_PASSWORD_RESET,
    'POST',
    {
      email: email,
    },
  )
    .then((result) => {
      return Promise.resolve(result.response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function resetPassword(
  hash: string,
  password: string,
  confirmation: string,
): Promise<any> {
  return fetchServer<ServerResponse<object>>(ENDPOINTS.RESET_PASSWORD, 'POST', {
    hash: hash,
    password: password,
    confirm: confirmation,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
