import React from 'react';
import { Card } from '../card/Card';
import { Title } from '../title/Title';
import DoctorBg from '../../../assets/loonaWeb/cards/doctor_bg.png';
import ArtActivitiesBg from '../../../assets/loonaWeb/cards/art_activities_bg.png';
import TreeBg from '../../../assets/loonaWeb/cards/tree_bg.png';
import TreeBgMobile from '../../../assets/loonaWeb/mobile/tree_bg.png';
import BreathingPracticesBg from '../../../assets/loonaWeb/cards/breathing_practices_bg.png';
import SoundHealingBg from '../../../assets/loonaWeb/cards/sound_healing_bg.png';
import Sign from '../../../assets/loonaWeb/cards/doctor_signature.png';
import ArtActivitiesProgress from '../../../assets/loonaWeb/cards/art_activities_progress.png';
import ArtActivitiesPhone from '../../../assets/loonaWeb/cards/art_activities_phone.png';
import Doctor from '../../../assets/loonaWeb/cards/doctor.png';
import BreathingPracticesPhone from '../../../assets/loonaWeb/cards/breathing_practices_phone.png';
import { TABLET_QUERY, useMediaQuery } from '../../../hooks/useMediaQuery';
import { ReactComponent as ArrowRight } from '../../../assets/loonaWeb/arrow_right.svg';

import styles from './styles.module.scss';

export const RelaxTechniques = () => {
  const isLargeScreen = useMediaQuery(TABLET_QUERY);

  const onHandleClick = () => {
    window.location.href =
      'https://medium.com/sleepscapism/sleepscapes-the-reason-behind-happy-snoozing-3abd68a785ae';
  };

  return (
    <div className={styles.container}>
      <div className={styles.topGradient} />
      <div className={styles.background} />
      <Title className={styles.title}>
        Backed by{'\n'} science-based{'\n'}
        <span> relaxation techniques</span>
      </Title>
      <button className={styles.button} onClick={onHandleClick}>
        <div className={styles.overlay} />
        Scientific evidence
        <ArrowRight />
      </button>

      <div className={styles.cardsWrapper}>
        <Card
          className={styles.bundles}
          background={DoctorBg}
          title={
            <div className={styles.bundlesTitle}>
              Loóna bundles the most popular science-based relaxation techniques
              in one product:{' '}
              <span>guided imagery, art activities, calming sounds,</span> and{' '}
              <span>breathing exercises.</span>
            </div>
          }
        >
          <div className={styles.subtitle}>
            <b>Jennifer Mundt</b>
            <br />
            PhD, Sleep Psychologist, Northwestern Medicine Sleep Disorders
            Center
          </div>
          <img src={Sign} className={styles.sign} alt="" />
          <img src={Doctor} className={styles.doctor} alt="" />
        </Card>
        <Card
          className={styles.artActivities}
          background={ArtActivitiesBg}
          title="Art activities"
          description={
            <div className={styles.description}>
              We have integrated art activities into our escapes, as they have
              been associated with{' '}
              <span className={styles.accent}>
                improved emotional and mental well-being.
              </span>
            </div>
          }
        >
          <img
            src={ArtActivitiesProgress}
            className={styles.progress}
            alt="progress"
          />
          <img src={ArtActivitiesPhone} className={styles.phone} alt="card" />
        </Card>
        <Card
          className={styles.tree}
          background={isLargeScreen ? TreeBg : TreeBgMobile}
          title={
            <div className={styles.treeTitle}>
              Guided imagery generates safety and nurturance
              {'\n'}
              <span>
                by reframing emotions to rebalance the nervous system.
              </span>
            </div>
          }
        />
        <Card
          className={styles.breathingPractices}
          background={BreathingPracticesBg}
          title={`Breathing ${'\n'}practices`}
          description={
            <div className={styles.description}>
              Experts recommend breathing exercises as a way of{' '}
              <span className={styles.accent}>
                coping with anxiety by regulating your heart rate.
              </span>
            </div>
          }
        >
          <img className={styles.phone} src={BreathingPracticesPhone} alt="" />
        </Card>
        <Card
          className={styles.soundHealing}
          background={SoundHealingBg}
          title={<div className={styles.soundHealingTitle}>Sound healing</div>}
          description={
            <>
              Research shows that listening to music{' '}
              <b>influences your body physically and improves sleep quality.</b>
            </>
          }
        />
      </div>
      <div className={styles.bottomGradient} />
    </div>
  );
};
