import React from 'react';
import styles from './styles.module.scss';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const Title = ({ children }: Props) => (
  <h1 className={styles.title}>{children}</h1>
);
