import React, { useEffect, useState } from 'react';
import { appAnalytics } from '../../../analytics';
import { useAppDispatch, useAppSelector } from '../../../bootstrap/hooks';
import { RedeemGiftCodeActivated, RedeemScreenOpened } from '../analytics';
import { activateGiftCode } from '../net';
import { InputWithButton } from './inputwithbutton/InputWithButton';
import { useTranslation } from './../../bidease/i18n/useTranslation';
import { RedeemTemplate } from '../components/redeemtemplate/RedeemTemplate';
import { push } from 'connected-react-router';
import styles from './styles.module.scss';

export const RedeemCode = () => {
  const dispatch = useAppDispatch();
  const translations = useTranslation();
  const { giftCode: initialCode, giftCodeError } = useAppSelector(
    (state) => state.redeem,
  );
  const [giftCode, setGiftCode] = useState(initialCode || '');
  const [giftCodeApplied, setGiftCodeApplied] = useState(false);
  const [error, setError] = useState<JSX.Element | string>(giftCodeError || '');

  useEffect(() => {
    appAnalytics.trackEvent(new RedeemScreenOpened('redeemGiftCode'));
    onRedeem();
  }, []);

  const onRedeem = () => {
    const code = giftCode;
    activateGiftCode(code)
      .then((_) => {
        setGiftCodeApplied(true);
      })
      .catch((error) => {
        if (error.code === 409 && error.description === 'DATA_EXIST') {
          setError(
            <div>
              {translations.get('activate_code_validation_line1')}
              <a href="https://loona.onelink.me/Jfkp/rocketdata">
                {translations.get('activate_code_validation_line2')}{' '}
              </a>
              {translations.get('activate_code_validation_line3')}
            </div>,
          );
        }
        if (error.code === 404) {
          setError(translations.get('invalid_code_validation_error'));
        }
      });
  };

  const onGiftCodeChange = (newGiftCode: string) => {
    setError('');
    setGiftCode(newGiftCode);
  };

  const onContinue = () => {
    appAnalytics.trackEvent(new RedeemGiftCodeActivated(giftCode));
    dispatch(push('done'));
  };

  return (
    <RedeemTemplate>
      <div className={styles.title}>
        {translations.get('redeem_code_title_line1')}{' '}
        <span className={styles.accent}>
          {translations.get('redeem_code_title_line2')}
        </span>
      </div>
      <InputWithButton
        value={giftCode}
        placeholder={translations.get('gift_code_button_placeholder')}
        applied={giftCodeApplied}
        error={error}
        onChange={onGiftCodeChange}
        onButtonClick={onRedeem}
      />
      <button
        className={styles.submitButton}
        onClick={onContinue}
        disabled={!giftCodeApplied}
      >
        {translations.get('activate_button')}
      </button>
      <div className={styles.helpSection}>
        {translations.get('help_text_line1')}
        <br />
        <a href="mailto:hello@loona.app">
          {translations.get('help_text_line2')}
        </a>
      </div>
    </RedeemTemplate>
  );
};
