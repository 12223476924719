import React from 'react';
import { PageWrapper } from '../components/pagewrapper/PageWrapper';

export const WebAccessibilityStatement = () => {
  return (
    <PageWrapper>
      <h2>WEB ACCESSIBILITY STATEMENT</h2>
      <p style={{ marginTop: '20px' }}>
        <span>GENERAL</span>
      </p>
      <p>
        <span>
          Loona Inc. cares about all of our customers and is committed to
          facilitating and improving the accessibility and usability of its
          Website, <a href="https://loona.app">https://loona.app </a>
          (the "Website") and its mobile application (the “Loona App”), ensuring
          that the Website and Loona App services and content are accessible to
          persons with disabilities including, but not limited to users of
          screen reader technology. Loona uses commercially reasonable efforts
          to take such action as is necessary and appropriate to improve the
          accessibility of Loona Website and Loona App in accordance with the
          WCAG 2.1 AA standards. In today’s dynamic and ever-changing technology
          environment, Loona understands the importance of continually testing
          its digital offerings to ensure consistent experiences are delivered
        </span>
      </p>
      <p>
        <span>DISCLAIMER</span>
      </p>
      <p>
        <span>
          Please be aware that our efforts to maintain accessibility and
          usability are ongoing. While we strive to make the Website and Loona
          App as accessible as possible some issues may be encountered by
          different assistive technology as the range of assistive technology is
          wide and varied. We appreciate your understanding.
        </span>
      </p>
      <p>
        <span>CONTACT LOONA WITH ANY ACCESSIBILITY QUESTIONS OR COMMENTS</span>
      </p>
      <p>
        <span>
          If, at any time, you have specific questions or concerns about the
          accessibility of any particular web page on the Website or the
          accessibility of any function of the Loona App, then please contact us
          by e-mail at hello@loona.app. If you do encounter an accessibility
          issue, then please be sure to specify the web page or App function and
          nature of the issue, and we will make all reasonable efforts to make
          that page (or the information contained therein) or the App function
          accessible for you. Thanks for visiting.
        </span>
      </p>
    </PageWrapper>
  );
};
