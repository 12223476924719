import React from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './styles.module.scss';

type Props = {
  children: (string | JSX.Element)[];
  className?: string;
};

export const Title = ({ children, className }: Props) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className={`${styles.title} ${className} ${inView ? styles.animate : ''}`}
    >
      {children}
    </div>
  );
};
