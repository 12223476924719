import { Amplitude } from './Amplitude';
import { Analytics } from './Analytics';
import { DummyAnalytics } from './DummyAnalytics';
import { FacebookPixel } from './FacebookPixel';
import { GoogleAnalytics } from './GoogleAnalytics';
import { Event } from './Event';

class AppAnalytics {
  private analytics: Analytics[];

  constructor(analytics: Analytics[]) {
    this.analytics = analytics;
  }

  trackEvent(event: Event) {
    this.analytics.forEach((it: Analytics) => {
      if (it.supportedEvents.includes(event.name)) {
        it.trackEvent(event);
      }
    });
  }
}

export const appAnalytics = new AppAnalytics(
  process.env.NODE_ENV === 'production'
    ? [new Amplitude(), new FacebookPixel(), new GoogleAnalytics()]
    : [
        new DummyAnalytics(),
        new Amplitude(),
        new FacebookPixel(),
        new GoogleAnalytics(),
      ],
);
