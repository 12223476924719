import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import { RedeemAccount as B2bRedeemAccount } from '../b2b/redeem/account/RedeemAccount';
import { RedeemCode as B2bRedeemCode } from '../b2b/redeem/code/RedeemCode';
import { RedeemDone as B2bRedeemDone } from '../b2b/redeem/done/RedeemDone';

import { CreatePassword } from '../resetpassword/createpassword/CreatePassword';
import { Done } from '../resetpassword/done/Done';
import { Email } from '../resetpassword/email/Email';
import { ExpiredHash } from '../resetpassword/expiredhash/ExpiredHash';
import './AppRouter.scss';
import '../common.scss';
import '../cookieconsent.scss';
import '../cookiesettings.scss';
import { LoonaWeb } from '../loonaweb/LoonaWeb';
import { Terms } from '../loonaweb/pages/Terms';
import { Privacy } from '../loonaweb/pages/Privacy';
import { CaliforniaPrivacy } from '../loonaweb/pages/CaliforniaPrivacy';
import { CookiePolicy } from '../loonaweb/pages/CookiePolicy';
import { Bidease } from '../b2b/bidease/Bidease';
import { Rocket10 } from '../b2b/bidease/Rocket10';
import { Conditions } from '../loonaweb/pages/Conditions';
import { WebAccessibilityStatement } from '../loonaweb/pages/WebAccessibilityStatement';

const AppRouter = () => {
  return (
    <AnimatedSwitch
      atEnter={{
        opacity: 0,
      }}
      atLeave={{
        opacity: 1,
      }}
      atActive={{
        opacity: 1,
      }}
      className="switch-wrapper"
    >
      <Route exact path="/" component={LoonaWeb} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/privacy" component={Privacy} />
      <Route
        exact
        path="/web-accessibility-statement"
        component={WebAccessibilityStatement}
      />
      <Route exact path="/conditions" component={Conditions} />
      <Route exact path="/california-privacy" component={CaliforniaPrivacy} />
      <Route exact path="/cookie-policy" component={CookiePolicy} />
      <Route exact path="/reset-password" component={Email} />
      <Route exact path="/reset-password/expired" component={ExpiredHash} />
      <Route exact path="/reset-password/done" component={Done} />
      <Route exact path="/reset-password/:hash" component={CreatePassword} />

      <Route exact path="/:lang/bidease" component={Bidease} />
      <Route exact path="/:lang/rocket10" component={Rocket10} />

      <Route
        path="/bidease"
        render={({ location }) => (
          <Redirect
            to={`/${navigator.language.startsWith('ru') ? 'ru' : 'en'}${
              location.pathname
            }`}
          />
        )}
      />

      <Route
        path="/rocket10"
        render={({ location }) => (
          <Redirect
            to={`/${navigator.language.startsWith('ru') ? 'ru' : 'en'}${
              location.pathname
            }`}
          />
        )}
      />

      <Route
        exact
        path="/:lang/b2b/redeem/account"
        component={B2bRedeemAccount}
      />
      <Route exact path="/:lang/b2b/redeem/code" component={B2bRedeemCode} />
      <Route exact path="/:lang/b2b/redeem/done" component={B2bRedeemDone} />

      <Route path="/">
        <Redirect to="/" />
      </Route>
    </AnimatedSwitch>
  );
};

export default AppRouter;
