import React from 'react';
import { appAnalytics } from '../../../analytics';
import GooglePlay from '../../../assets/loonaWeb/app_google.png';
import { Install } from '../../analytics';
import { getOneLink } from '../../utils';

export const GooglePlayButton = () => {
  const handleClick = () => {
    appAnalytics.trackEvent(new Install());

    window.location.href = getOneLink({
      mediaSource: 'website',
      webDp:
        'https://play.google.com/store/apps/details?id=co.loona&hl=en&gl=US',
      campaign: 'website-android',
      retargeting: 'true',
      iosUrl:
        'https://play.google.com/store/apps/details?id=co.loona&hl=en&gl=US',
    });
  };
  return (
    <a onClick={handleClick}>
      <img src={GooglePlay} alt="Download Loóna App" />
    </a>
  );
};
