import React from 'react';

type Props = {
  onMove?: () => void;
  onClick?: () => void;
  index?: number;
  active?: boolean;
};

const LinkItem = ({ onMove, onClick, active, index }: Props) => {
  const navigationItems = [
    'Escapes',
    'Immersive stories',
    'Music & Nature sounds',
    'Breathing exercises',
  ];

  return (
    <li
      className={`react-multi-carousel-item ${active ? 'active' : ''}`}
      onClick={() => {
        if (onMove) {
          onMove();
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      {navigationItems[index || 0]}
    </li>
  );
};

export default LinkItem;
