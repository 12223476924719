import React, { ReactElement } from 'react';
import { useTranslation } from '../../i18n/useTranslation';
import styles from './styles.module.scss';

type Props = {
  theme?: string;
  logo: React.ReactNode;
  companyName: string | ReactElement;
};

export const Letter = ({ logo, theme, companyName }: Props) => {
  const translations = useTranslation();

  return (
    <section className={`${styles.letter} ${theme ? styles[theme] : ''}`}>
      <h3>
        {translations.get('letter_title_line1')}{' '}
        {translations.get('letter_title_line2')} {companyName}{' '}
        {translations.get('letter_title_line3')}
      </h3>
      <p>{translations.get('letter_text_line1')}</p>
      <p>{translations.get('letter_text_line2')} </p>
      <p>
        {translations.get('letter_text_line3')}
        <span className={styles.accent}>
          {' '}
          {translations.get('letter_text_line4')}
        </span>
        {translations.get('letter_text_line5')}
      </p>
      <p>{translations.get('letter_text_line6')}</p>
      <div className={styles.sign}>
        <p className={styles.title}>{translations.get('letter_text_line7')}</p>
        <span className={styles.logo}>{logo}</span>
      </div>
    </section>
  );
};
