import { Events } from './Events';

type EventParams = {} | string[] | string;

export class Event {
  name: Events;
  params?: EventParams;
  eventId?: string;

  constructor(name: Events, params?: EventParams, eventId?: string) {
    this.name = name;
    this.params = params;
    this.eventId = eventId;
  }
}
