import React from 'react';
import { Description } from '../description/Description';
import { Title } from '../title/Title';
import Cards from '../../../../assets/bidease/cards.png';
import { useTranslation } from '../../i18n/useTranslation';
import styles from './styles.module.scss';

type Props = {
  theme?: string;
  companyName: string | JSX.Element;
};

export const WhyLoona = ({ companyName, theme }: Props) => {
  const translations = useTranslation();

  return (
    <section className={`${styles.container} ${theme ? styles[theme] : ''} `}>
      <Title>
        <div className={styles.title}>
          {translations.get('why_loona_title_line1')}{' '}
          <span className={styles.accent}>{companyName}</span>{' '}
          {translations.get('why_loona_title_line2')}
        </div>
      </Title>
      <img src={Cards} className={styles.cardsBackground} />
      <Description>
        <div className={styles.description}>
          <span className={styles.accent}>
            {' '}
            {translations.get('why_loona_title_line3')}
          </span>{' '}
          {translations.get('why_loona_title_line4')}
        </div>
      </Description>
    </section>
  );
};
