import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { redeemReducer } from '../b2b/redeem/redux/reducer';
import { RedeemGiftCardState } from '../b2b/redeem/redux/state';

export interface RootState {
  router: RouterState;
  redeem: RedeemGiftCardState;
}

export const rootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    redeem: redeemReducer,
  });
