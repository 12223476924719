import React from 'react';
import { useTranslation } from '../../../i18n/useTranslation';
import styles from './styles.module.scss';

type Props = {
  title: string;
  description: string;
  imageSrc: any;
  theme?: string;
};

export const Item = ({ imageSrc, title, description, theme }: Props) => {
  const translations = useTranslation();

  return (
    <div className={`${styles.advantageItem} ${theme ? styles[theme] : ''}`}>
      {imageSrc}
      <div className={styles.textWrapper}>
        <div className={styles.title}>{translations.get(title)}</div>
        <div className={styles.description}>
          {translations.get(description)}
        </div>
      </div>
    </div>
  );
};
