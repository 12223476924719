import React from 'react';
import styles from './styles.module.scss';

type Props = {
  text: string | JSX.Element;
  background: string;
};

const ContentCard = ({ background, text }: Props) => {
  return (
    <div className={`${styles.card}`}>
      <div className={styles.content}>
        <img
          className={styles.background}
          src={background}
          draggable={false}
          alt=""
        />
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

export default ContentCard;
