import React from 'react';
import { Loader } from '../loader/Loader';
import './styles.scss';

type Props = {
  disabled: boolean;
  className?: string;
  isLoading: boolean;
  label: string;
  onClick: () => void;
};

export const LoadingButton = ({
  disabled,
  className,
  isLoading,
  label,
  onClick,
}: Props) => (
  <button
    className={`loading-button ${className}`}
    type="submit"
    disabled={disabled}
    onClick={(event) => {
      event.preventDefault();
      !isLoading && onClick();
    }}
  >
    {isLoading ? <Loader /> : label}
  </button>
);
