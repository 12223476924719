import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as Flower } from '../../assets/bidease/flower.svg';
import { ReactComponent as Moon } from '../../assets/bidease/moon.svg';
import { ReactComponent as Smile } from '../../assets/bidease/smile.svg';
import { ReactComponent as Perfomance } from '../../assets/bidease/perfomance.svg';

export interface Advantage {
  title: string;
  description: string;
  imageSrc: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const advantages: Advantage[] = [
  {
    title: 'advantages_title_item1',
    description: 'advantages_description_item1',
    imageSrc: Flower,
  },
  {
    title: 'advantages_title_item2',
    description: 'advantages_description_item2',
    imageSrc: Moon,
  },
  {
    title: 'advantages_title_item3',
    description: 'advantages_description_item3',
    imageSrc: Smile,
  },
  {
    title: 'advantages_title_item4',
    description: 'advantages_description_item4',
    imageSrc: Perfomance,
  },
];
