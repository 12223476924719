import React from 'react';
import { PageWrapper } from '../components/pagewrapper/PageWrapper';

export const Conditions = () => {
  return (
    <PageWrapper>
      <h2>APP TERMS AND CONDITIONS </h2>
      <ol>
        <li>
          <b>Apple users</b>
          <p>
            <span>
              1.1 If you download the App from Apple’s App Store and / or are
              using the App on an Apple device the following additional terms
              shall apply and, to the extent that there is any inconsistency
              between the terms set out in this paragraph 1 and the rest of
              these terms, the terms set out in this paragraph 1 shall prevail:
            </span>
          </p>
          <p>
            <span>
              1.1.1
              <u>
                <i> Acknowledgement.</i>
              </u>{' '}
              You and we acknowledge that this contract is concluded between us,
              and not with Apple, and we, not Apple, are solely responsible for
              the App and the content thereof. These terms and conditions do not
              provide for usage rules for licensed applications that are in
              conflict with the{' '}
              <a href="https://www.apple.com/uk/legal/internet-services/itunes/uk/terms.html">
                Apple Media Services Terms and Conditions.
              </a>
            </span>
          </p>
          <p>
            <span>
              1.1.2{' '}
              <u>
                <i>Scope of Licence.</i>
              </u>{' '}
              The licence granted to you for the App is limited to a
              non-transferable licence to use the App on any Apple-branded
              products that you own or control and as permitted by the Usage
              Rules set forth in the{' '}
              <a href="https://www.apple.com/uk/legal/internet-services/itunes/uk/terms.html">
                Apple Media Services Terms and Conditions
              </a>
              , except that the App may be accessed and used by other accounts
              which you hold, for example via Family Sharing or volume
              purchasing.
            </span>
          </p>
          <p>
            <span>
              1.1.3{' '}
              <u>
                <i>Maintenance and Support.</i>
              </u>{' '}
              We are solely responsible for providing any maintenance and
              support services with respect to the App, as specified in these
              terms and conditions, or as required under applicable law. You and
              we acknowledge that Apple has no obligation whatsoever to furnish
              any maintenance and support services with respect to the App.
            </span>
          </p>
          <p>
            <span>
              1.1.4{' '}
              <u>
                <i>Warranty.</i>
              </u>{' '}
              We are solely responsible for any product warranties, whether
              express or implied by law, to the extent not effectively
              disclaimed. In the event of any failure of the App to conform to
              any applicable warranty, you may notify Apple, and Apple will
              refund the purchase price for the App to you; and, to the maximum
              extent permitted by applicable law, Apple will have no other
              warranty obligation whatsoever with respect to the App, and any
              other claims, losses, liabilities, damages, costs or expenses
              attributable to any failure to conform to any warranty will be our
              sole responsibility.
            </span>
          </p>
          <p>
            <span>
              1.1.5{' '}
              <u>
                <i>Product Claims.</i>
              </u>{' '}
              You and we acknowledge that we, not Apple, are responsible for
              addressing any claims by you or any third party relating to the
              App or your possession and/or use of the App, including, but not
              limited to: (i) product liability claims; (ii) any claim that the
              App fails to conform to any applicable legal or regulatory
              requirement; and (iii) claims arising under consumer protection,
              privacy, or similar legislation, including in connection with the
              App’s use of the HealthKit and HomeKit frameworks.
            </span>
          </p>
          <p>
            <span>
              1.1.6{' '}
              <u>
                <i>Intellectual Property Rights.</i>{' '}
              </u>{' '}
              You and we acknowledge that, in the event of any third party claim
              that the App or your possession and use of the App infringes that
              third party’s intellectual property rights, we, not Apple, will be
              solely responsible for the investigation, defence, settlement and
              discharge of any such intellectual property infringement claim.
            </span>
          </p>
          <p>
            <span>
              1.1.7{' '}
              <u>
                <i>Legal Compliance.</i>
              </u>{' '}
              You represent and warrant that (i) you are not located in a
              country that is subject to a U.S. Government embargo, or that has
              been designated by the U.S. Government as a “terrorist supporting”
              country; and (ii) you are not listed on any U.S. Government list
              of prohibited or restricted parties.
            </span>
          </p>
          <p>
            <span>
              1.1.8{' '}
              <u>
                <i>Developer Name and Address.</i>
              </u>{' '}
              [insert details] is a company registered in [insert details] with
              company number [insert details]. Our registered address is [insert
              details] and any questions, complaints or claims with respect to
              these terms and conditions may be directed to that address, via
              email to [insert details].
            </span>
          </p>
          <p>
            <span>
              1.1.9{' '}
              <u>
                <i>Third Party Terms of Agreement.</i>
              </u>{' '}
              You must comply with applicable third-party terms of agreement
              when using the App, and you must not be in violation of your
              wireless data service agreement when using the App.
            </span>
          </p>
          <p>
            <span>
              1.1.10{' '}
              <u>
                <i>Third Party Beneficiary.</i>
              </u>{' '}
              You and we acknowledge and agree that Apple, and Apple’s
              subsidiaries, are third party beneficiaries of these terms and
              conditions, and that, upon your acceptance of these terms and
              conditions, Apple will have the right (and will be deemed to have
              accepted the right) to enforce these terms and conditions against
              you as a third party beneficiary thereof.
            </span>
          </p>
        </li>
        <li>
          <b>Google users</b>
        </li>
        <p>
          <span>
            If you download the App from Google Play, the ways in which you can
            use the App may also be controlled by Google Play’s rules and
            policies, including Google Play’s{' '}
            <a href="https://play.google.com/intl/en-US_us/about/play-terms/index.html">
              Terms of Service.
            </a>
            Google is not a party to this agreement and has no responsibility or
            liability under it.
          </span>
        </p>
      </ol>
    </PageWrapper>
  );
};
