import React from 'react';
import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick: () => void;
};

export const Button = ({ className, children, onClick }: Props) => (
  <button className={`${className} ${styles.button}`} onClick={onClick}>
    {children}
  </button>
);
