import React, { useState } from 'react';
import ContentCard from '../card/Card';
import Carousel from 'react-multi-carousel';
import LinkItem from '../item/linkItem';
import {
  TABLET_QUERY,
  useMediaQuery,
} from '../../../../../hooks/useMediaQuery';
import 'react-multi-carousel/lib/styles.css';
import styles from './styles.module.scss';

import MusicContentBg from '../../../../../assets/loonaWeb/cards/music_content.png';
import SleepscapesContentBg from '../../../../../assets/loonaWeb/cards/sleepscapes_content.png';
import StoriesContentBg from '../../../../../assets/loonaWeb/cards/stories_content.png';
import EpisodeContentBg from '../../../../../assets/loonaWeb/cards/episode_content.png';

import MobileSleepscapesContentBg from '../../../../../assets/loonaWeb/mobile/sleepscapes_content.png';
import MobileMusicContentBg from '../../../../../assets/loonaWeb/mobile/music_content.png';
import MobileStoriesContentBg from '../../../../../assets/loonaWeb/mobile/stories_content.png';
import MobileEpisodeContentBg from '../../../../../assets/loonaWeb/mobile/episode_content.png';
import { usePreloadImages } from '../../../../../hooks/usePreloadImages';

const ContentCarousel = () => {
  const isLargeScreen = useMediaQuery(TABLET_QUERY);
  const [isScrollActive, setScrollActive] = useState(false);

  usePreloadImages(
    isLargeScreen
      ? [
          MusicContentBg,
          SleepscapesContentBg,
          StoriesContentBg,
          EpisodeContentBg,
        ]
      : [
          MobileSleepscapesContentBg,
          MobileMusicContentBg,
          MobileStoriesContentBg,
          MobileEpisodeContentBg,
        ],
  );

  const getSlides = () => {
    return [
      {
        text: (
          <>
            <span className="accent">Escapes</span> invite you to color a
            magical rotating diorama while being guided by marvelous narration
            to gently occupy your mind.{' '}
            <span className="accent">
              Disengage from solving all the world’s problems while in bed,
            </span>{' '}
            and slip into sleep instead.
          </>
        ),
        background: isLargeScreen
          ? SleepscapesContentBg
          : MobileSleepscapesContentBg,
      },
      {
        text: (
          <>
            <span className="accent">
              Soothe the mind with unique sound design experiences.
            </span>{' '}
            Tailored music and sound effects for each tale take you on a
            360-audio-encounter{' '}
            <span className="accent">
              with the most compelling storytelling.
            </span>{' '}
            Unleash a sense of wonder and endless imagination.
          </>
        ),
        background: isLargeScreen ? StoriesContentBg : MobileStoriesContentBg,
      },
      {
        text: (
          <>
            Dive into <span className="accent">a world of original music</span>{' '}
            created with the help of analog instrumentation. Loóna’s gallery of
            sublime natural sounds is{' '}
            <span className="accent">
              designed to alleviate anxiety, promote relaxation, and provide a
              bit of magic to your evening routine.
            </span>
          </>
        ),
        background: isLargeScreen ? MusicContentBg : MobileMusicContentBg,
      },
      {
        text: (
          <>
            <span className="accent">Inhale calm, and exhale worry</span> with
            Loóna breathing exercises. Designed to inspire resilience,{' '}
            <span className="accent">
              take a deep breath and lose yourself in this centering experience.
            </span>
          </>
        ),
        background: isLargeScreen ? EpisodeContentBg : MobileEpisodeContentBg,
      },
    ];
  };

  const SLIDES = getSlides();

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      arrows={false}
      minimumTouchDrag={35}
      slidesToSlide={1}
      showDots
      customDot={<LinkItem />}
      className={isScrollActive ? styles.centeredDot : ''}
      swipeable
      afterChange={() => setScrollActive(false)}
      beforeChange={() => setScrollActive(true)}
    >
      {SLIDES.map((slide, idx) => (
        <ContentCard
          key={`slide-${idx}`}
          text={slide.text}
          background={slide.background}
        />
      ))}
    </Carousel>
  );
};

export default ContentCarousel;
