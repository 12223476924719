import React from 'react';
import { Advantage } from '../../constants';
import { Item } from './item/item';
import styles from './styles.module.scss';

type Props = {
  adventages: Advantage[];
  theme?: string;
};

export const Advantages = ({ adventages, theme }: Props) => {
  return (
    <section className={styles.advantagesContainer}>
      {adventages.map((adventage: Advantage, index: number) => {
        return (
          <Item
            key={index}
            title={adventage.title}
            description={adventage.description}
            imageSrc={<adventage.imageSrc />}
            theme={theme}
          />
        );
      })}
    </section>
  );
};
