import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Website } from './app';
import { getSubDomain } from './utils';
import { VR } from './vr';
import { Avatars } from './avatars';
import './index.scss';

let Component: React.FC;

switch (getSubDomain()) {
  case 'vr':
  case 'vr-dev': {
    Component = VR;
    break;
  }
  case 'avatars':
  case 'avatars-dev': {
    Component = Avatars;
    break;
  }
  default: {
    Component = Website;
    break;
  }
}
ReactDOM.render(<Component />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
