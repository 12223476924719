import React from 'react';
import { Logo } from '../logo/Logo';
import GiftCard from '../../../../assets/bidease/gift_card_nooverlay.png';
import styles from './styles.module.scss';

type Props = {
  title: string;
  subtitle?: string;
  description: string | JSX.Element;
  button: string | JSX.Element;
  onButtonClick?: () => void;
};

export const SuccessTemplate = ({
  title,
  subtitle,
  description,
  button,
  onButtonClick,
}: Props) => {
  const handleButtonClick = () => {
    onButtonClick?.();
  };

  return (
    <div className={styles.contentContainer}>
      <Logo className={styles.logo} />
      <div className={styles.textWrapper}>
        <img src={GiftCard} className={styles.image} alt="" />
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.description}>{description}</div>
        {typeof button === 'string' ? (
          <button onClick={handleButtonClick}>{button}</button>
        ) : (
          button
        )}
      </div>
    </div>
  );
};
