import React from 'react';
import styles from './styles.module.scss';
import { useInView } from 'react-intersection-observer';

type Props = {
  title: JSX.Element | string;
  description?: JSX.Element | string;
  background: string;
  children?: JSX.Element | JSX.Element[];
  className: string;
};

export const Card = ({
  title,
  description,
  background,
  children,
  className,
}: Props) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`
        ${styles.container} 
        ${className} 
        ${inView ? styles.appear : ''}
      `}
    >
      <img className={styles.background} src={background} />
      <div className={`${styles.content} ${inView ? styles.animate : ''}`}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        {children}
      </div>
    </div>
  );
};
