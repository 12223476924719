import React from 'react';
import { getOneLink } from '../../loonaweb/utils';
import { Template } from '../template/Template';

export const Done = () => {
  const handleSubmit = () => {
    window.location.href = getOneLink(
      {
        mediaSource: 'web_payments_dev',
        webDp: 'https%3A%2F%2Floona.app%2F',
      },
      'login',
    );
  };

  return (
    <Template
      title={
        <span>
          <span className="accent">Password</span>
          <br />
          successfully changed
        </span>
      }
      buttonDisabled={false}
      buttonLabel="Continue in the app"
      isLoading={false}
      handleSubmit={handleSubmit}
    />
  );
};
