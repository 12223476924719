import React from 'react';
import { CookieDataTypes } from '../../data/CookieDataTypes';
import styles from './styles.module.scss';

type TableHeaders<T> = Record<keyof T, string>;

interface TableProps<T> {
  items: T[];
  headers: TableHeaders<T>;
}

const objectValues = <T extends {}>(obj: T) =>
  Object.keys(obj).map((objKey) => obj[objKey as keyof T]);

const objectKeys = <T extends {}>(obj: T) =>
  Object.keys(obj).map((objKey) => objKey as keyof T);

export default function Table<T>({ headers, items }: TableProps<T>) {
  const getColumnColorStyle = (columnValue: CookieDataTypes) => {
    return columnValue === CookieDataTypes.Marketing ? 'purple' : 'green';
  };

  function renderRow(item: T) {
    return (
      <tr>
        {objectKeys(item).map((itemProperty, index) => {
          const columnValue = item[itemProperty];
          if (itemProperty === 'type') {
            return (
              <td key={index}>
                <span
                  className={`
                    ${styles.label} 
                    ${
                      // @ts-ignore
                      styles[getColumnColorStyle(columnValue)]
                    }
                  `}
                >
                  {columnValue}
                </span>
              </td>
            );
          }
          return <td key={index}>{columnValue}</td>;
        })}
      </tr>
    );
  }

  return (
    <table className={styles.table}>
      <thead>
        {objectValues(headers).map((headerValue, index) => (
          <th key={index}>{headerValue}</th>
        ))}
      </thead>
      <tbody>{items.map(renderRow)}</tbody>
    </table>
  );
}
