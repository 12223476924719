import ReactPixel from 'react-facebook-pixel';
import { Events } from './Events';
import { Analytics } from './Analytics';
import { Event } from './Event';

const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID || '';

const mappedEvents: Record<string, string> = {
  [Events.Install]: 'Lead',
  [Events.SignUp]: 'CompleteRegistration',
};

export class FacebookPixel extends Analytics {
  constructor() {
    super();
    ReactPixel.init(FACEBOOK_PIXEL_ID, undefined, {
      debug: process.env.NODE_ENV !== 'production',
      autoConfig: true,
    });
  }

  trackEvent(event: Event) {
    const eventName = mappedEvents[event.name];
    const track = () => {
      ReactPixel.track(eventName, event.params);
    };
    track();
  }
}
