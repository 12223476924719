import React from 'react';
import { Logo } from '../logo/Logo';
import Cards from '../../../../assets/bidease/cards.png';
import styles from './styles.module.scss';

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export const RedeemTemplate = ({ children }: Props) => {
  return (
    <div className={styles.contentContainer}>
      <Logo className={styles.logo} />
      <div className={styles.textWrapper}>
        <img src={Cards} className={styles.image} alt="" />
        {children}
      </div>
    </div>
  );
};
