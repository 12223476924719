export class Translation {
  translation: Object;

  constructor(translation: Object) {
    this.translation = translation;
  }

  get(key: string): any {
    if (key in this.translation) {
      // @ts-ignore
      return this.translation[key];
    }
    return key;
  }
}
