import AppleAwardLogo from '../../../assets/loonaWeb/logos/apple_award.svg';
import BazaarLogo from '../../../assets/loonaWeb/logos/bazaar.svg';
import CNNLogo from '../../../assets/loonaWeb/logos/cnn.svg';
import ForbesLogo from '../../../assets/loonaWeb/logos/forbes.svg';
import GoogleAwardLogo from '../../../assets/loonaWeb/logos/google_award.svg';
import GoogleLogo from '../../../assets/loonaWeb/logos/google.svg';
import GQLogo from '../../../assets/loonaWeb/logos/gq.svg';
import QuartzLogo from '../../../assets/loonaWeb/logos/quartz.svg';
import TechCrunchLogo from '../../../assets/loonaWeb/logos/tech_crunch.svg';
import TheGuardianLogo from '../../../assets/loonaWeb/logos/the_guardian.svg';
import VogueLogo from '../../../assets/loonaWeb/logos/vogue.svg';
import AlwaysPets from '../../../assets/loonaWeb/logos/always_pets.svg';
import FamilyMinded from '../../../assets/loonaWeb/logos/family_minded.svg';
import FarAndWide from '../../../assets/loonaWeb/logos/far_wide.svg';
import WorkAndMoney from '../../../assets/loonaWeb/logos/work_money.svg';
import StadiumTalk from '../../../assets/loonaWeb/logos/stadium_talk.svg';

type Logo = {
  url: string;
  image: string;
};

export const Logos: Logo[] = [
  {
    image: GoogleLogo,
    url: 'https://play.google.com/store/apps/topic?id=campaign_editorial_bestof2020_bestapp&hl=en_US&fbclid=IwAR1vqN-gHmTxjo0spab3CP4R4ACHN6tDil9V1TbBoJ2neqjo32iG43h1LDs',
  },
  {
    image: AppleAwardLogo,
    url: 'https://www.apple.com/newsroom/2021/06/apple-announces-winners-of-the-2021-apple-design-awards/',
  },
  {
    image: VogueLogo,
    url: 'https://www.vogue.co.uk/beauty/article/natalia-vodianova-loona-app',
  },
  { image: GQLogo, url: 'https://www.gq.ru/success/loona' },
  {
    image: ForbesLogo,
    url: 'https://www.forbes.com/sites/stephanrabimov/2021/05/14/how-a-supermodel-took-on-the-science-of-sleep/?sh=57d5c4f35768',
  },
  {
    image: BazaarLogo,
    url: 'https://www.harpersbazaar.com/uk/fashion/fashion-news/a37167248/natalia-vodianova-cover-interview/',
  },
  {
    image: TechCrunchLogo,
    url: 'https://techcrunch.com/2020/12/01/google-plays-best-of-2020-awards-highlight-the-stressful-year-its-been/',
  },
  {
    image: GoogleAwardLogo,
    url: 'https://play.google.com/store/apps/topic?id=campaign_editorial_bestof2020_bestapp&hl=en_US&fbclid=IwAR1vqN-gHmTxjo0spab3CP4R4ACHN6tDil9V1TbBoJ2neqjo32iG43h1LDs',
  },
  {
    image: CNNLogo,
    url: 'https://edition.cnn.com/2020/12/02/tech/best-apps-of-2020/index.html',
  },
  {
    image: TheGuardianLogo,
    url: 'https://www.theguardian.com/lifeandstyle/2021/mar/07/from-posh-blankets-to-aural-apps-the-firms-cashing-in-on-the-demand-for-sleep-aids',
  },
  {
    image: QuartzLogo,
    url: 'https://qz.com/1958045/what-meditation-app-is-best-for-you/',
  },
  {
    image: AlwaysPets,
    url: 'https://www.alwayspets.com/',
  },
  {
    image: FamilyMinded,
    url: 'https://www.familyminded.com/',
  },
  {
    image: FarAndWide,
    url: 'https://www.farandwide.com/',
  },
  {
    image: WorkAndMoney,
    url: 'https://www.workandmoney.com/',
  },
  {
    image: StadiumTalk,
    url: 'https://www.stadiumtalk.com/',
  },
];
