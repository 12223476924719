import React, { useRef, useState } from 'react';
import { validateEmail } from '../../utils';
import { requestPasswordReset } from '../net/net';
import { Template } from '../template/Template';
import './style.scss';

export const Email = () => {
  const email = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);

  const handleSubmit = () => {
    if (email.current) {
      setLoading(true);
      requestPasswordReset(email.current.value)
        .then(() => {
          setLoading(false);
          setSentEmail(true);
        })
        .catch((error) => {
          setLoading(false);
          setError(error.debug_text || error || 'Unknown error');
        });
    }
  };

  const handleInputChange = () => {
    setError('');
    setIsValid(!!email.current && validateEmail(email.current?.value));
  };

  return (
    <Template
      title={
        <span>
          Reset your <span className="accent">password</span>
        </span>
      }
      description={
        sentEmail
          ? 'Please check your email inbox.\nYou will receive the password reset instructions if your Loóna account is linked with the email address you entered'
          : 'Please enter the email address that you used when creating your Loóna account'
      }
      buttonDisabled={!isValid || sentEmail}
      buttonLabel="Submit"
      buttonClassName={sentEmail ? 'checked-button' : undefined}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    >
      {sentEmail ? undefined : (
        <>
          <input
            ref={email}
            type="email"
            className={isValid ? '' : 'invalid'}
            placeholder="Email"
            onChange={handleInputChange}
          />
          <div className="reset-password-error">{error}</div>
        </>
      )}
    </Template>
  );
};
