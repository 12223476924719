import { CookieDataTypes } from './CookieDataTypes';

export const CookieTableData = [
  {
    name: '_fbp',
    description:
      'Used by Facebook to deliver a series of advertisement products.',
    duration: '3 months',
    type: CookieDataTypes.Marketing,
  },
  {
    name: '_ga',
    description:
      'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
    duration: '2 years',
    type: CookieDataTypes.Performance,
  },
  {
    name: `_gat_gtag_UA${'\n'}_145929721_1`,
    description: 'Google Analytics cookie to throttle the request rate.',
    duration: '1 minute',
    type: CookieDataTypes.Performance,
  },
  {
    name: '_gid',
    description:
      'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
    duration: '1 day',
    type: CookieDataTypes.Performance,
  },
  {
    name: 'amp_*',
    description:
      'Contains a token that can be used to retrieve a Client ID from AMP Client ID service.',
    duration: '1 year',
    type: CookieDataTypes.Marketing,
  },
  {
    name: '_scid',
    description:
      'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
    duration: '1 year',
    type: CookieDataTypes.Marketing,
  },
  {
    name: '_pin_unauth',
    description:
      'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
    duration: '1 year',
    type: CookieDataTypes.Marketing,
  },
  {
    name: '_gcl_au',
    description:
      'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
    duration: '3 months',
    type: CookieDataTypes.Marketing,
  },
  {
    name: 'wd',
    description:
      'Used by Facebook to deliver a series of advertisement products.',
    duration: '1 week',
    type: CookieDataTypes.Marketing,
  },
  {
    name: 'IDE',
    description: 'Used by oogle to deliver a series of advertisement products.',
    duration: '13 months',
    type: CookieDataTypes.Marketing,
  },
  {
    name: 'fr',
    description:
      'Used by Facebook to deliver a series of advertisement products.',
    duration: '3 months',
    type: CookieDataTypes.Marketing,
  },
  {
    name: 'dpr',
    description:
      'Used by Facebook to deliver a series of advertisement products.',
    duration: '3 day',
    type: CookieDataTypes.Marketing,
  },
  {
    name: 'datr',
    description:
      'Used by Facebook to deliver a series of advertisement products.',
    duration: '2 year',
    type: CookieDataTypes.Marketing,
  },
  {
    name: 'usida',
    description:
      'Used by facebook to register a unique ID that is used to generate statistical data on how the visitor uses the website.',
    duration: 'Session',
    type: CookieDataTypes.Marketing,
  },
];
