import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PasswordInput } from '../../components/passwordinput/PasswordInput';
import { resetPassword } from '../net/net';
import { Template } from '../template/Template';
import './styles.scss';

const ERROR_TYPES = {
  PASSWORD_MISMATCH: 'PASSWORD_MISMATCH',
  PASSWORD_WEAK: 'PASSWORD_WEAK',
  NO_INTERNET_CONNECTION: 'NO_INTERNET_CONNECTION',
  UNKNOWN: 'UNKNOWN',
};

const ERRORS = {
  [ERROR_TYPES.PASSWORD_MISMATCH]: "Passwords don't match",
  [ERROR_TYPES.PASSWORD_WEAK]: 'Password should be at least 8 characters',
  [ERROR_TYPES.NO_INTERNET_CONNECTION]: 'No internet connection',
  [ERROR_TYPES.UNKNOWN]: 'Unknown error',
};

export const CreatePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [isConfirmationValid, setConfirmationValid] = useState(false);
  const { hash }: any = useParams();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (password && confirmation) {
      setLoading(true);
      resetPassword(hash, password, confirmation)
        .then((result) => {
          if (result.error.code === 0) {
            setLoading(false);
            dispatch(push('/reset-password/done'));
          } else if (result.error.code === 404) {
            setLoading(false);
            dispatch(push('/reset-password/expired'));
          } else if (result.error.code === 500) {
            setLoading(false);
            const errorCode =
              result.error.debug_text.password ||
              result.error.debug_text.confirm;
            setError(ERRORS[errorCode || ERROR_TYPES.UNKNOWN]);
          } else {
            setLoading(false);
            setError(result.error.debug_text || result.error || ERRORS.UNKNOWN);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error.debug_text || error || ERRORS.UNKNOWN);
        });
    }
  };

  const handleInputChange = (
    currentPassword: string,
    currentConfirmation: string,
  ) => {
    if (currentPassword.length < 8) {
      setPasswordValid(false);
      setConfirmationValid(false);
      setError(ERRORS.PASSWORD_WEAK);
    } else if (currentPassword !== currentConfirmation) {
      setPasswordValid(true);
      setConfirmationValid(false);
      setError(ERRORS.PASSWORD_MISMATCH);
    } else {
      setPasswordValid(true);
      setConfirmationValid(true);
      setError('');
    }
  };

  return (
    <Template
      title={
        <span>
          Reset your <span className="accent">password</span>
        </span>
      }
      buttonDisabled={!isPasswordValid || !isConfirmationValid}
      buttonLabel="Change password"
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    >
      <PasswordInput
        value={password}
        isValid={isPasswordValid}
        placeholder="New password"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault();
          setPassword(event.target.value);
          handleInputChange(event.target.value, confirmation);
        }}
      />
      <PasswordInput
        value={confirmation}
        isValid={isConfirmationValid}
        placeholder="Confirm password"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault();
          setConfirmation(event.target.value);
          handleInputChange(password, event.target.value);
        }}
      />
      <div className="reset-password-error">{error}</div>
      <div className="reset-password-space" />
    </Template>
  );
};
