export const updateFavicons = (faviconName: string) => {
  const favicons = document.querySelectorAll('link[rel="icon"]');

  favicons.forEach((favicon) => {
    favicon?.setAttribute(
      'href',
      `${process.env.PUBLIC_URL}/${faviconName}_favicon.png`,
    );
  });
};
