import React from 'react';
import { Header } from './components/header/Header';
import { Ticker } from './components/ticker/Ticker';
import { HeroSection } from './components/herosection/HeroSection';
import { Content } from './components/content/Content';
import { RelaxTechniques } from './components/relaxtechniques/RelaxTechniques';
import { Pillow } from './components/pillow/Pillow';
import { FaqAccordion } from './components/accordion/Accordion';
import { Footer } from './components/footer/Footer';
import { Banner } from './components/banner/Banner';
import styles from './styles.module.scss';
import AppleLogo from '../assets/apple.svg';

export const LoonaWeb = () => {
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          padding: '5px 0',
          backgroundColor: '#d295ff',
          width: '100%',
        }}
      >
        <a
          href="https://loona.vision"
          style={{
            color: '#000',
            fontSize: 'small',
            textDecoration: 'none',
            display: 'block',
          }}
        >
          We are happy to announce Loóna for{' '}
          <img
            src={AppleLogo}
            alt="Apple"
            style={{
              display: 'inline-block',
              verticalAlign: '-0.02rem',
              height: '0.65rem',
            }}
          />{' '}
          Vision Pro
        </a>
      </div>
      <div className={styles.container}>
        <Header />
        <Ticker />
        <HeroSection />
        <Content />
        <RelaxTechniques />
        <FaqAccordion />
        <Pillow />
        <Footer />
        <Banner />
      </div>
    </>
  );
};
