import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import LogoImage from '../assets/logo_loona.svg';

export const Avatars = () => {
  useEffect(() => {
    document.title = 'Loónaverse — Social Wellbeing Experience';
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.background} />

      <div className={styles.mobileBackgroundGradient} />

      <img className={styles.logo} src={LogoImage} alt="Loóna" />

      <div className={styles.form}>
        <div className={styles.backgroundGradient} />

        <div className={styles.textContainer}>
          <span className={`${styles.title} ${styles.highlighted}`}>
            Loónaverse
          </span>
        </div>

        <div className={styles.textContainer}>
          <span className={`${styles.title} ${styles.flex}`}>
            Social Wellbeing Experience
          </span>
        </div>

        <div className={styles.textContainer}>
          <span className={styles.description}>
            Loóna is proud to announce the first social wellbeing platform to
            deliver by far the most immersive mindful experience to date.
          </span>
        </div>
      </div>
    </div>
  );
};
