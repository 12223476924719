import React from 'react';
import styles from './styles.module.scss';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const Description = ({ children }: Props) => (
  <div className={styles.description}>{children}</div>
);
