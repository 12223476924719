import React from 'react';
import { Card } from '../../components/card/Card';
import BreadCrumbs from '../../../assets/loonaWeb/cards/anxiety_breadcrumb.png';
import EaseIntoSleepPhone from '../../../assets/loonaWeb/cards/ease_into_sleep_phone.png';
import WakeUpPhone from '../../../assets/loonaWeb/cards/wake_up_phone.png';
import PerformBetterProgress from '../../../assets/loonaWeb/cards/perform_better_progress.png';
import AnxietyBg from '../../../assets/loonaWeb/cards/anxiety_bg.png';
import EaseIntoSleepBg from '../../../assets/loonaWeb/cards/ease_into_sleep_bg.png';
import WakeUpBg from '../../../assets/loonaWeb/cards/wake_up_bg.png';
import PerformBetterBg from '../../../assets/loonaWeb/cards/perform_better_bg.png';
import styles from './styles.module.scss';

type Card = {
  id: string;
  background: string;
  title: JSX.Element;
  description?: JSX.Element;
  image: string;
};

export const HeroSection = () => {
  return (
    <div className={styles.container}>
      <Card
        className={styles.anxiety}
        background={AnxietyBg}
        title={
          <div className={styles.anxietyTitle}>
            Relieve anxiety.{' '}
            <span className={styles.accent}>
              Interact with calming activities{' '}
            </span>
            to hold your focus just long enough
            {'\n'}to
            <span className={styles.accent}> quiet your racing thoughts.</span>
          </div>
        }
      >
        <img src={BreadCrumbs} className={styles.breadCrumbs} alt="" />
      </Card>
      <Card
        className={styles.sleep}
        background={EaseIntoSleepBg}
        title={
          <div className={styles.title}>
            Ease <span className={styles.accent}>into sleep</span>
          </div>
        }
        description={
          <div className={styles.description}>
            <span className={styles.accent}>
              Create a comforting yet engaging nightly routine
            </span>{' '}
            filled with wonder to blanket you in slumber.
          </div>
        }
      >
        <img src={EaseIntoSleepPhone} className={styles.phone} alt="" />
      </Card>
      <Card
        className={styles.wakeUp}
        background={WakeUpBg}
        title={
          <div className={styles.title}>
            <span className={styles.accent}>Wake up </span>refreshed
          </div>
        }
        description={
          <div className={styles.description}>
            <span className={styles.accent}>
              It’s hard to be a morning person if you haven’t slept all night.{' '}
            </span>
            Treat yourself to rest and wake up on the right side of the bed.
          </div>
        }
      >
        <img src={WakeUpPhone} className={styles.phone} alt="" />
      </Card>
      <Card
        className={styles.perform}
        background={PerformBetterBg}
        title={
          <>
            <span className={styles.accent}>Perform</span> better
          </>
        }
        description={
          <div className={styles.description}>
            <span className={styles.accent}>
              Utilize your sleep to jumpstart your body and mind.{' '}
            </span>
            Usher in a better, more productive, and creative new day.
          </div>
        }
      >
        <img src={PerformBetterProgress} className={styles.progress} alt="" />
      </Card>
    </div>
  );
};
