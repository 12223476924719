import { Events } from './Events';
import { Event } from './Event';

export class ExperimentStarted extends Event {
  constructor(name: string, value: number) {
    super(Events.ExperimentStarted, {
      experiment: name,
      variant: value,
    });
  }
}
