import React from 'react';
import { useTranslation } from '../../i18n/useTranslation';
import { Button } from '../button/Button';
import { Title } from '../title/Title';
import styles from './styles.module.scss';

type Props = {
  logo: JSX.Element;
  theme?: string;
  headerImage: string;
  handleClick: () => void;
};

export const Header = ({ logo, theme, headerImage, handleClick }: Props) => {
  const translations = useTranslation();

  return (
    <header className={`${styles.header} ${theme ? styles[theme] : ''}`}>
      <img src={headerImage} className={styles.image} alt="" />
      <div className={`${styles.contentWrapper}`}>
        <span className={styles.logo}>{logo}</span>
        <Title>
          <div className={styles.title}>{translations.get('header_title')}</div>
        </Title>
        <Button className={styles.button} onClick={handleClick}>
          {translations.get('gift_button')}
        </Button>
      </div>
    </header>
  );
};
