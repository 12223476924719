import React from 'react';
import { CookiePolicyPageWrapper } from '../components/cookiepagewrapper/CookiePolicyPageWrapper';
import styles from '../components/cookiepagewrapper/styles.module.scss';
import Table from '../components/table/Table';
import { CookieTableData } from '../data/CookieTableData';
import { CookieTableHeaders } from '../data/CookieTableHeaders';

export const CookiePolicy = () => {
  return (
    <CookiePolicyPageWrapper>
      <h1>Cookie notice</h1>
      <p className={styles.note}>
        <span className={styles.light}>Last update: </span>
        April 19, 2022
      </p>

      <p className={styles.description}>
        <span>
          This cookie notice applies to our website{' '}
          <a href="https://loona.app">https://loona.app</a> and its related
          pages, which are operated by and on behalf of Loona, Inc.
        </span>
      </p>

      <h2>What are cookies?</h2>
      <p>
        <span>
          Cookies are small text files stored by your web browser on your
          computer or mobile device. Some cookies are essential for a website to
          work and help us provide you with relevant information. The other ones
          allow us to recognize your computer and browser (but not you!) and to
          store your website preferences. Cookies from our website cannot read
          any information contained in your hard disk or read any cookie files
          created by other websites.
        </span>
      </p>

      <h2>What cookies do we use?</h2>
      <p className={styles.bottom0}>
        <span>
          Generally, there are many different ways to classify cookies. Most
          common ways are classification by duration, by provenance and by
          purpose.
        </span>
      </p>

      <h3>Classified by their duration, there are:</h3>
      <ul className="c28 lst-kix_list_2-0 start">
        <li>
          <span className={styles.bold}>Session cookies.</span> Such cookies
          expire once you close your browser (or once your session ends).
        </li>
        <li>
          <span className={styles.bold}>Persistent cookies.</span> Such cookies
          are stored on your hard drive until you erase them or until your
          browser erases them, depending on the cookie’s expiration date. All
          cookies have an expiration date, which is commonly embedded into their
          code.
        </li>
      </ul>

      <h3>Classified by their provenance, there are:</h3>
      <ul className="c28 lst-kix_list_2-0 start">
        <li>
          <span className={styles.bold}>First-party cookies.</span> Such cookies
          are put on your device directly from this website.
        </li>
        <li>
          <span className={styles.bold}>Third-party cookies.</span> Such cookies
          are put on your device by a third party - e.g., by an advertiser or an
          analytic system.
        </li>
      </ul>

      <h3>Classified by their purpose, there are:</h3>
      <ol className="c28 lst-kix_list_2-0 start">
        <li>
          <span className={styles.bold}>Strictly Necessary cookies. </span>
          These are cookies that are essential for the operation of our website.
          Such cookies are generally first-party session cookies.
        </li>
        <li>
          <span className={styles.bold}>Functionality cookies. </span>
          These cookies allow the website to recognize you when you return to
          our website and to remember your choices – for example, your preferred
          language, your username and password for automatic log in.
        </li>
        <li>
          <span className={styles.bold}>Performance cookies. </span>
          These cookies collect the information about your usage of the website
          – for example, the pages that you visited or the links that you
          clicked on. All such information is anonymized, so it cannot be used
          to identify you. Such cookies are used solely to improve the website
          functionality – sometimes, by help of third-party analytic services.
        </li>
        <li>
          <span className={styles.bold}>Marketing cookies. </span>
          These cookies track your online activity and they may be shared with
          other organizations or advertisers. Generally, such cookies are used
          by the advertisers to deliver more personalized advertising to you.
          These are commonly persistent cookies and most always of third-party
          provenance.
        </li>
      </ol>

      <h3>
        For better understanding of the cookies that we use, please take a look
        at the table below:
      </h3>
      <div className={styles.tableWrapper}>
        <Table headers={CookieTableHeaders} items={CookieTableData} />
      </div>

      <h2>Considering turning off and deleting cookies?</h2>
      <p>
        <span>
          When you use our site, we’ll give you a choice to consent to all of
          the cookies we’ve listed above (except for the “Strictly Necessary
          Cookies”, which are collected automatically). You can revoke your
          consent to accept any cookies and delete them by activating the
          respective setting on your browser. By that means you can delete
          already set cookies and choose not to accept the new ones. If you use
          different devices to view and access our website (for example, your
          computer, smartphone, tablet etc.), you will need to ensure that each
          browser on each device is adjusted to suit your cookie preferences.
        </span>
      </p>

      <p className={styles.bottom0}>
        <span>
          Please note that if you disable the cookies that we use, this may
          impact your experience while on the website.
        </span>
      </p>

      <h3>
        Should you need more information on the cookie settings for the most
        popular browsers, you can follow the links below:
      </h3>
      <p>
        <a href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
          Microsoft Internet Explorer
        </a>
      </p>
      <p>
        <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform=Desktop&hl=en">
          Google Chrome
        </a>
      </p>
      <p>
        <a href="https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DAndroid">
          Google Chrome app
        </a>
      </p>
      <p>
        <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">
          Mozilla Firefox
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">
          Safari (Desktop)
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/en-us/HT201265">Safari (Mobile)</a>
      </p>
      <p>
        <a href="https://help.opera.com/en/latest/web-preferences/">
          Opera browser
        </a>
      </p>
      <p>
        <a href="https://help.opera.com/en/latest/web-preferences/">
          Opera Mobile
        </a>
      </p>

      <h2>Want to learn more?</h2>
      <p>
        <span>
          You can find more information about the cookies and the way they
          function by visiting the websites below:
        </span>
      </p>
      <p>
        <a href="https://www.allaboutcookies.org/">
          https://www.allaboutcookies.org/
        </a>
      </p>
      <p className={styles.bottom0}>
        <a href="https://www.aboutcookies.org/">
          https://www.aboutcookies.org/
        </a>
      </p>

      <h2>Everything changes…</h2>
      <p>
        <span>
          And this cookie notice may change from time to time, too. Nonetheless,
          stay tuned! Any changes made to our cookie notice in the future will
          be posted on our website so that you can always access the up-to-date
          version.
        </span>
      </p>

      <h2>Contact us</h2>
      <p>
        <span>
          Questions, comments or requests related to this cookie notice are
          always welcome. Feel free to contact us via:
          <a href="hello@loona.app"> hello@loona.app</a>
        </span>
      </p>
    </CookiePolicyPageWrapper>
  );
};
