import { Reducer } from 'redux';
import { RedeemActionTypes } from './actions';
import { SET_CODE_PREFETCH } from './actions/code';
import { SET_AUTH_TOKEN, SET_SUBSCRIPTION } from './actions/register';
import { RedeemGiftCardState } from './state';

const INITIAL_STATE: RedeemGiftCardState = {
  authToken: undefined,
  hasSubscription: undefined,
  giftCode: undefined,
  giftCodeError: undefined,
};

export const redeemReducer: Reducer<RedeemGiftCardState, RedeemActionTypes> = (
  state = INITIAL_STATE,
  action: RedeemActionTypes,
) => {
  switch (action.type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        hasSubscription: action.payload.hasSubscription,
        oldUser: action.payload.oldUser,
      };
    }

    case SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: action.token,
      };
    }

    case SET_CODE_PREFETCH: {
      return {
        ...state,
        giftCode: action.payload.code,
        giftCodeError: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};
