import { Analytics } from './Analytics';
import amplitude from 'amplitude-js';
import { Event } from './Event';
import { Events } from './Events';

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || '';

export class Amplitude extends Analytics {
  unsupportedEvents: Events[] = [];

  supportedEvents = Object.values(Events).filter(
    (event) => !this.unsupportedEvents.includes(event),
  );

  constructor() {
    super();
    amplitude.getInstance().init(AMPLITUDE_API_KEY, undefined, {
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
    });
  }

  static setUserProperty(name: string, value: any) {
    amplitude.getInstance().setUserProperties({
      [name]: value,
    });
  }

  trackEvent(event: Event) {
    amplitude.getInstance().logEvent(event.name, event.params);
  }
}
