import React, { useEffect, useState } from 'react';
import { appAnalytics } from '../../../analytics';
import Delete from '../../../assets/ic_delete.svg';
import { useAppDispatch } from '../../../bootstrap/hooks';
import { LoadingButton } from '../../../components/loadingbutton/LoadingButton';
import { PasswordInput } from '../../../components/passwordinput/PasswordInput';
import { validateEmail } from '../../../utils';
import { RedeemScreenOpened, RedeemSignUp } from '../analytics';
import { ErrorPopup } from '../components/errorpopup/ErrorPopup';
import { emailRegister } from '../redux/actions/register';
import { FacebookLoginButton } from './facebook/FacebookLoginButton';
import { useTranslation } from './../../bidease/i18n/useTranslation';
import styles from './styles.module.scss';

export const RedeemAccount = () => {
  const dispatch = useAppDispatch();
  const translations = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    appAnalytics.trackEvent(new RedeemScreenOpened('redeemAccount'));
  }, []);

  const handleSubmit = async (): Promise<void> => {
    setIsErrorVisible(false);
    if (!isLoading && validateForm()) {
      setIsLoading(true);
      dispatch(emailRegister(email, password))
        .then(() => {
          setIsLoading(false);
          appAnalytics.trackEvent(new RedeemSignUp('email'));
        })
        .catch((message) => {
          setIsLoading(false);
          if (message.email) {
            setEmailError(translations.get('email_validation_error'));
          } else if (message.password) {
            setPasswordError(translations.get('password_validation_error'));
          } else {
            setError(message);
            setIsErrorVisible(true);
          }
        });
    }
  };

  const validateForm = (): boolean => {
    const isEmailValid = validateEmail(email);
    const isPasswordValid = password !== '';
    if (!isEmailValid) {
      setEmailError(translations.get('email_validation_error'));
      return false;
    } else if (!isPasswordValid) {
      setPasswordError(translations.get('password_validation_error'));
      return false;
    } else return true;
  };

  const onSocialError = (type: 'facebook', error: string | any) => {
    setError(error);
    setIsErrorVisible(true);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    const isValid = validateEmail(event.target.value);
    setEmailError(isValid ? '' : translations.get('email_validation_error'));
    setIsErrorVisible(false);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError(
      event.target.value.length < 8
        ? translations.get('password_validation_error_2')
        : '',
    );
    setPassword(event.target.value);
    setIsErrorVisible(false);
  };

  const content = (
    <div className={styles.contentContainer}>
      <div className={styles.title}>Sign in</div>
      <div className={styles.description}>
        {translations.get('sign_in_description')}
      </div>
      <FacebookLoginButton onError={onSocialError} />
      <form className={styles.formContainer} onSubmit={handleSubmit} noValidate>
        <div className={styles.inputContainer}>
          <input
            type="email"
            className={emailError !== '' && email !== '' ? 'invalid' : ''}
            value={email}
            placeholder={translations.get('email')}
            onChange={onEmailChange}
          />
          {email && (
            <img
              src={Delete}
              alt="x"
              className={styles.delete}
              onClick={() => setEmail('')}
            />
          )}
        </div>

        <div className={styles.errorLabel}>{emailError}</div>

        <PasswordInput
          placeholder={translations.get('password')}
          value={password}
          isValid={!passwordError}
          onChange={onPasswordChange}
        />

        <div className={styles.errorLabel}>{passwordError}</div>

        <span className={styles.legal}>
          {translations.get('legal_note')}
          <br />
          <a href="https://loona.app/terms.html">
            {translations.get('terms')}
          </a>{' '}
          &{' '}
          <a href="https://loona.app/privacy.html">
            {translations.get('privacy')}
          </a>
        </span>

        <LoadingButton
          disabled={
            email === '' ||
            emailError !== '' ||
            password === '' ||
            passwordError !== ''
          }
          isLoading={isLoading}
          label={translations.get('continue_button')}
          onClick={handleSubmit}
        />

        <p className={styles.disclaimer}>
          {translations.get('secure_info_text')}
        </p>
      </form>
    </div>
  );

  return (
    <>
      <ErrorPopup
        visible={isErrorVisible}
        error={error}
        onClose={() => setIsErrorVisible(false)}
      />
      {content}
    </>
  );
};
