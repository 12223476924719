import React, { useEffect } from 'react';
import { Footer } from '../footer/Footer';
import BlackLogo from '../../../assets/logo_loona_black.svg';
import styles from './styles.module.scss';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const CookiePolicyPageWrapper = ({ children }: Props) => {
  useEffect(() => {
    document.body.classList.add(styles.bodyBackground);

    return () => {
      document.body.classList.remove(styles.bodyBackground);
    };
  });

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={BlackLogo} alt="" />
      <div className={styles.article}>{children}</div>
      <Footer />
    </div>
  );
};
