import React from 'react';
import { LoadingButton } from '../../components/loadingbutton/LoadingButton';
import './styles.scss';

import Logo from '../../assets/logo_loona.svg';

type Props = {
  title: JSX.Element | string;
  description?: string;
  children?: JSX.Element[] | JSX.Element;
  buttonDisabled: boolean;
  buttonLabel: string;
  buttonClassName?: string;
  isLoading: boolean;
  handleSubmit: () => void;
};

export const Template = ({
  title,
  description,
  children,
  buttonDisabled,
  buttonLabel,
  buttonClassName,
  isLoading,
  handleSubmit,
}: Props) => {
  return (
    <div className="reset-password-template">
      <img className="reset-template-logo" src={Logo} alt="Loóna" />
      <div className="reset-template-image" />
      <div className="reset-password-content">
        <h1 className="reset-password-title">{title}</h1>
        {description && (
          <div className="reset-password-description">{description}</div>
        )}
        {children}
        <LoadingButton
          className={buttonClassName}
          disabled={buttonDisabled}
          onClick={handleSubmit}
          isLoading={isLoading}
          label={buttonLabel}
        />
      </div>
    </div>
  );
};
