import React from 'react';
import { PageWrapper } from '../components/pagewrapper/PageWrapper';

export const Privacy = () => {
  return (
    <PageWrapper>
      <h2>LOÓNA PRIVACY POLICY</h2>
      <p>
        <i>Effective Date: April 25, 2024.</i>
      </p>
      <>
        <p>
          This privacy policy explains how we, Loona Inc. of 8 The Green, Ste B,
          Dover, DE, 19901, USA or any of our affiliated entities(
          <strong>“Loóna”, “we”, “us”, “our”</strong>) process your personal
          information (<strong>“you”, “your”</strong>) when you use our website,
          app or other services.
        </p>
        <p>PLEASE READ THIS PRIVACY POLICY CAREFULLY&nbsp;</p>
        <p>
          By using the Loóna mobile application (the “<strong>App</strong>”) or
          the website located at{' '}
          <a href="https://loona.app/">https://loona.app/</a> (the “
          <strong>Website</strong>”) (App and Website individually referred to
          as a <strong>“Loóna Service”</strong> or collectively as the{' '}
          <strong>“Loóna Services”</strong>) or otherwise indicating your
          acceptance (e.g. by clicking tick-box or bottom “agree” or
          “continue”), you agree to the following terms of our Privacy Policy,
          which will bind you at all times and in whatever manner you use the
          Loóna Services.
        </p>
        <p>Please note:</p>
        <ol>
          <li>
            Individuals in the European Economic Area (EEA) and the United
            Kingdom (UK) should also read our section about “
            <strong>Your EEA &amp; UK Data Privacy Rights</strong>”.
          </li>
          <li>
            Individuals in <strong>California</strong> should also read our
            section about “Your California Privacy Rights”.
          </li>
          <li>
            Individuals in <strong>Nevada</strong> should also read our section
            about “Your Nevada Privacy Rights”.
            <br />
            <br />
            <br />
          </li>
        </ol>
        <ul>
          <li>
            <strong>1. WHAT THIS PRIVACY POLICY APPLIES TO</strong>
          </li>
        </ul>
        <p>This Privacy Policy applies to all Loóna Services.&nbsp;</p>
        <ul>
          <li>
            <strong>2. WHAT TYPES OF INFORMATION DO WE COLLECT</strong>
          </li>
        </ul>
        <p>
          Generally, “personal information”, “personal data” or similar terms
          refer to any information that identifies you or relates to you.
          However, the exact meaning of personal information may be determined
          by the law of the country of your residence.
        </p>
        <p>
          We will process the following information which may be considered
          personal information:
        </p>
        <p>
          <strong>Information You Provide To Us Directly</strong>
        </p>
        <ul>
          <li>
            <strong>Account details.</strong>Your user details when you sign up
            as a user of Loóna Services, such as your name/ user name and email
            address. If you log in with your Apple ID we will receive your Apple
            ID and may receive the email address associated with your Apple ID,
            depending on your Apple settings. If you log in via your Google
            Account we will receive your associated email address.
          </li>
          <li>
            <strong>Details of your request. </strong>When you contact or visit
            us, we may process the details of your request, communications,
            complaints, or other interaction.
          </li>
          <li>
            <strong>Activity Data and Health Data.</strong> Information you
            provide to us when you participate in any interactive features of
            Loóna Services, including surveys, contests, promotions. For
            instance, if you choose to take our test to determine your ideal
            sleep schedule, you can voluntarily share personal information with
            us. This may include details such as your gender identity, age
            group, whether you have children, information about your sleep and
            activity patterns, and health data. This information will be solely
            utilized for generating test results and performing statistical
            analysis. The retained data will be stored for up to 12 months and
            will be automatically deleted. The health data you provide will be
            processed on the basis of your consent, which you will be able to
            withdraw at any time by contacting us at privacy@loona.app.&nbsp;
          </li>
        </ul>
        <p>
          <strong>Information We Collect Automatically</strong>
        </p>
        <p>
          Such automatically collected data helps us operate Loóna and improve
          it to deliver better service, including but not limited to enabling us
          to estimate our audience size, and understand how you use Loóna and
          what you like and dislike the most.
        </p>
        <ul>
          <li>
            <strong>Transaction information. </strong>Transaction information
            such as your purchases in our Loóna Services, including date of
            purchase, currency, amount, payment type and the last four digits of
            your card number.
          </li>
          <li>
            <strong>App and country information:</strong> Information regarding
            the version of the app that you are using and the country version of
            the app store from which you downloaded Loóna.
          </li>
          <li>
            <strong>Device information. </strong>When accessing Loóna Services,
            your device and browser will automatically provide unique
            information such as account ID, mobile device ID, internet protocol
            (IP) address, cookie ID or online identifiers, operating system,
            browser type, time zone setting, location and date and time of
            access<strong>. </strong>
            <strong>
              (If you have provided your consent) IDFA or Android Advertising ID
            </strong>
            , whichever is applicable to your device. If you want to disable the
            collection of IDFA and/or Android Advertising ID by Loóna Services,
            please follow in-app instructions.
          </li>
        </ul>
        <p>If you use an iOS device:</p>
        <p>
          Go to Privacy settings to see a list of apps that request to track
          your activity. On iPhone or iPad, go to Settings &gt; Privacy &gt;
          Tracking.
        </p>
        <p>Tap to turn off or turn on permission to track for Loóna.</p>
        <p>If you use an Android device:</p>
        <p>Open Settings app</p>
        <p>Navigate to “Privacy” &gt; “Ads”</p>
        <p>Tap “Delete Advertising ID”</p>
        <p>Tap it again on the next page to confirm.</p>
        <p>
          We and third-parties may use cookies, Software Development Kits
          (SDKs), and other tracking technologies to automatically collect the
          Personal Data set forth above. For more information regarding our use
          of these technologies, please see Section 6: Cookies, Software
          Development Kits, and Other Tracking Technologies.
        </p>
        <ul>
          <li>
            <strong>Usage data. </strong>Details of your use of Loóna, including
            frequency of use, areas and features of the application that you
            access and information regarding engagement with particular features
            of the app.
          </li>
          <li>
            <strong>Interaction information. </strong>When we send you emails or
            other communications, we may collect technical interaction
            information, such as open rates and if you clicked on any content.
          </li>
        </ul>
        <p>
          <strong>Information We Obtain From Third Parties</strong>
        </p>
        <p>
          In addition to the information you may provide us directly, we receive
          information about you from others, including:
        </p>
        <ul>
          <li>
            <strong>Social media. </strong>You may decide to share information
            with us through your social media account, such as your interaction
            with our posts and content, social media profile by sharing your
            “stories” and ‘likes’ on social media platforms, such as Twitter and
            Instagram, TikTok.
          </li>
          <li>
            <strong>Our suppliers. </strong>We may receive information about you
            from our partners where our ads are published on a partner’s service
            (in which case they may pass along details on a campaign’s success).
            We may receive information about you from our analytics service
            providers to help us measure traffic and usage trends with data they
            collect through Loóna Services in accordance with their own privacy
            policies.
          </li>
        </ul>
        <ul>
          <li>
            <strong>3. DATA ACCURACY</strong>
          </li>
        </ul>
        <p>
          We will rely on the information provided by you as accurate, complete,
          and up to date, and we would be grateful if you could inform us of any
          changes.
        </p>
        <ul>
          <li>
            <strong>
              4. WHAT PURPOSES DO WE COLLECT YOUR INFORMATION FOR?
            </strong>
          </li>
        </ul>
        <p>
          Generally, we will use your personal information to (i) provide Loóna
          Services and enable you to use our service features, (ii) respond to
          your queries, (iii) develop and promote our organisation and services;
          (iv) ensure the security and technical availability of Loóna Services;
          and (v) comply with the law.
        </p>
        <p>
          *If you are an individual in the EEA or the UK, we have to inform you
          about the “legal ground” for us to use your “personal data”. This will
          typically be the <strong>performance of our contract</strong> with you
          (based on our
          <a href="https://loona.app/terms"> Loóna Terms of Use</a>) or our{' '}
          <strong>legitimate interest</strong> to use your personal data to
          ensure that Loóna Services are provided properly, efficiently and
          securely, as is further explained below. We may be{' '}
          <strong>obligated</strong> to process some of your personal data to
          comply with applicable laws and regulations.&nbsp;
        </p>
        <p>
          Where the legal basis is <strong>consent</strong>, you can withdraw
          consent at any time. You may withdraw your consent by adapting your
          settings or by deleting your content (for instance where you entered
          information in your profile that may be considered 'special' or
          'sensitive'). In any case, you may withdraw your consent at any time
          by contacting us at the address provided at the end of this Privacy
          Policy.
        </p>
        <p>
          Where we rely on legitimate interests as the legal basis for
          processing personal data, we have considered whether or not those
          interests are overridden by the interests or fundamental rights or
          freedoms of the individuals whose data are being processed and
          concluded that the processing is, on balance, fair. Where the legal
          basis is legitimate interests, you have a right to object to our use
          of your data.
        </p>
        <p>
          <strong>We use your information for the following purposes:</strong>
        </p>
        <ul>
          <li>
            To register you as a service user when you sign up for Loóna
            Services and to enable you to use Loóna Services and its
            functionalities.&nbsp;
          </li>
          <li>To enable you to make purchases through our Loóna Service.</li>
          <li>
            To assist and contact you in relation to your enquiry or to send you
            information which you have requested about Loóna Services.
          </li>
          <li>
            To fulfill your requests and send you service communications and
            notifications about matters relevant to your use of Loóna Services
            and your engagement with us, such as information about offers you
            might be interested in, targeted content, confirmations of
            purchases, details of events, surveys, changes in our terms, etc.
          </li>
          <li>
            To send you promotional information through various marketing
            channels including post, email, social media and etc. about Loóna
            Services and our organization, reviewing and optimizing campaign
            performance and profiling information about your interests known,
            observed or inferred for direct marketing purposes.
          </li>
          <li>
            To improve and develop Loóna Services, including to: obtain
            feedback; record preferences; conduct statistical analysis; make
            services and features more relevant; improve user experience; and
            work with third parties and evaluate data to improve and develop
            Loóna Services.
          </li>
          <li>
            To ensure proper administration of our business, including to: keep
            appropriate records about how Loóna Services are used; resolve
            complaints; conduct troubleshooting; manage our business
            relationships and identify opportunities; register interactions with
            our communications, such as emails; enforce our terms; and debt
            collection.
          </li>
          <li>
            To engage our third party service providers who may process your
            information on our behalf to facilitate the provision of Loóna
            Services and the fulfillment of essential service functions, such as
            web hosting, cloud storage, analytics, payments, plugins,
            communications, accounting, security and others. It is our
            legitimate interest in delivering our Loóna Services properly,
            efficiently and understanding our users’ needs and to make analytics
            of our audience as it helps us understand our product and business
            metrics.
          </li>
          <li>
            To monitor our networks, Loóna Services and systems for suspicious
            activities, test and audit our systems and deploy appropriate
            security measures.
          </li>
          <li>
            To monitor operations, user activity and networks for fraud
            prevention, including information from third parties who may alert
            us about suspicious activities.&nbsp;
          </li>
          <li>To share information with our group companies.</li>
          <li>
            To share data with another organization for the purposes of a joint
            venture, collaboration, financing, sale, merger, reorganization or
            similar event relating to our business.
          </li>
          <li>
            To process information as is required for our compliance with the
            law or to establish, exercise or defend legal claims.
          </li>
          <li>
            To process and share information with other third parties where
            required by law, such as regulators, law enforcement agencies or
            where mandatory under a court order.
          </li>
        </ul>
        <ul>
          <li>
            <strong>5. NO PROCESSING OF PAYMENT DETAILS</strong>
          </li>
        </ul>
        <p>
          We will not receive or store any of your payment details, this is
          fully handled by the relevant payment service provider. If/when you
          make any purchases, we are notified by the payment processor once the
          transaction takes place and then ensure you receive your purchase. We
          do not, however, receive any of your actual payment details or
          personal data related to the payment. We only keep the data concerning
          transaction dates, currencies, value, the last four digits of your
          card number and the subject of the transaction.
        </p>
        <ul>
          <li>
            <strong>
              6. COOKIES, SOFTWARE DEVELOPMENT KITS, AND OTHER TRACKING
              TECHNOLOGIES
            </strong>
          </li>
        </ul>
        <p>
          While using Loóna Services, we and our service providers, vendors, and
          partners, including third parties, may use cookies (a small text files
          stored on your computer or mobile device to identify your computer and
          web browser) and other similar technologies to collect or receive
          specific information regarding you and/or your interaction with Loóna
          Services.&nbsp;
        </p>
        <p>
          Additionally, we employ third-party analytics tools such as Google
          Firebase, RevenueCat, AppsFlyer, and Amplitude to assist us in
          measuring traffic and usage trends for Loóna, among other purposes.
          These analytics tools collect data through third-party SDKs integrated
          into Loóna, encompassing details about the features you explore or use
          within Loóna, your interactions within the Loóna, and information
          concerning your subscription. This information might be used to
          deliver content, advertising, or features, as well as to evaluate and
          analyze the effectiveness of advertisements on Loóna or other websites
          or platforms. Third parties may also leverage this data for their own
          purposes.&nbsp;
        </p>
        <p>
          <strong>Interest-based Advertising.</strong> We might collaborate with
          ad networks and other providers of ad-serving services to deliver
          advertisements on non-affiliated platforms. Some of these
          advertisements may be personalized, which means that they may try to
          be relevant to you using data collected by ad networks and ad-serving
          providers regarding your app usage patterns over time, including
          details about connections between various browsers and devices. This
          form of advertising is commonly referred to as interest-based
          advertising.
        </p>
        <p>
          <strong>Your Choices</strong>. Most browsers and devices are set to
          accept cookies and similar tracking technologies by default. However,
          you may have the option to set your browser and device settings to
          limit these technologies. For more information about cookies,
          including how to see what cookies have been set on your device and how
          to manage and delete them, visit
          <a href="www.allaboutcookies.org">www.allaboutcookies.org</a> .
        </p>
        <p>
          <u>
            For users in the European Economic Area, United Kingdom and United
            States.
          </u>{' '}
          You can opt-out from processing of Personal Data via cookies, SDKs and
          other tracking technologies by sending a request to
        </p>
        <p>
          Additionally, you have the option to prevent our Loóna Services from
          placing cookies by using a global privacy control signal or by using a
          browser equipped with privacy features or installing browser plugins
          and configuring them to block cookies or trackers.
        </p>
        <p>
          Please note that these opt-out mechanisms apply only to the specific
          device or browser on which they are activated, necessitating opting
          out on each browser and device individually.
        </p>
        <p>
          It's important to acknowledge that certain features of the app may not
          operate as intended if you choose to reject cookies or similar
          tracking technologies. Additionally, disabling cookies or similar
          tracking technologies could limit your access to certain content
          within our app. Remember that your preferences are typically tied to
          specific devices and browsers.
        </p>
        <ul>
          <li>
            <strong>
              7. HOW WE MAY DISCLOSE YOUR INFORMATION TO THIRD PARTIES
            </strong>
          </li>
        </ul>
        <p>
          We do not rent or sell your Personal Data to any third parties outside
          the Loóna or its affiliates (as defined below).
        </p>
        <p>
          We do not share your personal information except as approved by you or
          as described below:
        </p>
        <ul>
          <li>
            We may engage other companies and individuals to perform services on
            our behalf. An example of these services may include analyzing data
            and providing customer support. These agents and service providers
            may have access to your personal information in connection with the
            performance of services for us, and they are bound by appropriate
            contractual safeguards in place.
          </li>
          <li>
            We may release your information as permitted by law, such as to
            comply with a subpoena, or when we believe that release is
            appropriate to comply with the law; investigate fraud, respond to a
            government request, enforce or apply our rights; or protect the
            rights, property, or safety of us or our users, or others. This
            includes exchanging information with other companies and
            organizations for fraud protection. In certain situations, Loóna may
            be required to disclose personal information in response to lawful
            requests by public authorities or for local law enforcement
            requirements.
          </li>
          <li>
            We may share your Personal Data if our company or any of our
            affiliates, subsidiaries or business units is involved in a business
            change such as a merger, divestiture, restructuring, reorganization,
            acquisition, bankruptcy, dissolution, or liquidation. In these
            situations, your Personal Data and other collected data may be among
            the assets transferred or disclosed as part of the business
            transaction or proceeding.
          </li>
          <li>
            Loóna may contain third party tracking and data collection and
            analytics tools from our service providers. Such third parties may
            use cookies, APIs, and SDKs in Loóna to enable them to collect and
            analyze user and device related data and information on our behalf.
            We collect and use this analytics information in an aggregated
            manner with analytics information so that it cannot reasonably be
            used to identify any particular user. The privacy policies of our
            service providers may include additional terms and disclosures
            regarding their data collection and use practices and tracking
            technologies, and we encourage you to check those privacy policies
            to learn more about their data collection and use practices, use of
            cookies and other similar tracking technologies.
          </li>
          <li>
            Apple iOS users may opt-in to allow Loóna Services to provide data
            regarding the amount of minutes spent within Loóna applications to
            the Apple iOS “Health” application for display. This data will not
            be shared with third parties or used for marketing purposes.
          </li>
          <li>
            We may share aggregate or anonymized information about you with
            advertisers, publishers, business partners, sponsors, and other
            third parties.
          </li>
        </ul>
        <ul>
          <li>
            <strong>8. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
          </li>
        </ul>
        <p>
          Many web browsers, as well as certain mobile operating systems and
          applications, offer a feature called Do-Not-Track ("DNT") that allows
          you to indicate your preference for privacy by requesting that your
          online browsing activities not be monitored or collected. However,
          it's important to note that there is currently no universally accepted
          technology standard for recognizing and implementing DNT signals.
          Therefore, we do not currently act upon DNT browser signals or any
          other automatic mechanism that indicates your preference not to be
          tracked online. If a standard for online tracking is established in
          the future that requires our compliance, we will update this privacy
          notice accordingly to inform you of any changes in our practices. We
          do recognize the global privacy control signal if you rbrowser or
          device is using such a signal. Please see Section 6 (Your Choices)
          above for more information.
        </p>
        <ul>
          <li>
            <strong>9. LINKS TO OTHER SITES</strong>
          </li>
        </ul>
        <p>
          For the convenience of our visitors and clients, Loóna Services may
          contain links to other sites, such as those of our partners or
          vendors, which are subject to different privacy policies. The Policy
          will not apply to your use of other websites. While we generally try
          to link only to sites that share similar high standards and respect
          for privacy, we have no responsibility or liability for the content,
          products or services offered, independent actions, or the privacy and
          security practices employed by these other independent sites. We
          encourage you to ask questions and review the applicable privacy
          policies found on such other websites, services and applications to
          understand how your information may be collected and used on these
          independent sites before disclosing information to third parties.
        </p>
        <ul>
          <li>
            <strong>10. HOW LONG IS YOUR INFORMATION KEPT?</strong>
          </li>
        </ul>
        <p>
          We retain your Personal Data for as long as your account is active or
          as needed for the purposes of processing. At any time, you can delete
          your account, as well as the Personal Data associated with it by
          sending a request to privacy@loona.app or proceed with App Settings.
        </p>
        <p>
          Please note that we still may retain certain data about you if so
          needed due to applicable legislation requirements, any potential or
          ongoing dispute resolution, or in order to enforce our rights. Even if
          we delete some or all of your Personal Data, we may continue to retain
          and use anonymized data previously collected that can no longer be
          used for personal identification.
        </p>
        <p>
          We will retain your Personal Data only for as long as is necessary for
          the purposes set out in this Privacy Policy.&nbsp;
        </p>
        <p>
          Please, note that deletion of App does not imply the deletion of your
          account and your data.
        </p>
        <ul>
          <li>
            <strong>11. HOW DO WE SECURE YOUR INFORMATION?</strong>
          </li>
        </ul>
        <p>
          We maintain appropriate organizational and technological safeguards to
          help protect against unauthorized use, access to or accidental loss,
          alteration or destruction of the personal information we hold. We also
          seek to ensure our third-party service providers do the same.
        </p>
        <p>
          We will endeavour to use the least amount of personal information as
          is required for each purpose.&nbsp;
        </p>
        <p>
          Our staff will access your personal information on a “need-to-know”
          basis.
        </p>
        <p>
          Unfortunately, the transmission of personal data through the internet
          is not completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of your personal data
          transmitted to or stored on our IT system, and any transmission is at
          your own risk. Once we have received your personal data, we will use
          strict procedures and security features to try to prevent unauthorized
          access.
        </p>
        <ul>
          <li>
            <strong>12. WHERE IS YOUR INFORMATION PROCESSED?</strong>
          </li>
        </ul>
        <p>
          We may transfer your personal information to our group companies,
          suppliers and other third parties in countries different to your
          country of residence.
        </p>
        <p>
          In addition, data may be transferred to our subsidiaries or processors
          in third countries or subcontractors of our processors in third
          countries.
        </p>
        <p>
          We and certain of our service providers are incorporated in the United
          States. Accordingly, your Personal Data may be transferred to and
          stored in the United States.
        </p>
        <p>
          Where required under the EEA GDPR, in case of transfers of personal
          data from the EEA to countries outside the EEA, where we cannot rely
          on adequacy decisions adopted by the European Commission (for more
          information, please see{' '}
          <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en">
            here
          </a>
          ) we ensure appropriate safeguards are in place to guarantee the
          continued protection of your personal data, particularly by signing
          the Standard Contractual Clauses of the European Commission (article
          46(2)(c) GDPR). For more information on these Standard Contractual
          Clauses, please see{' '}
          <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">
            here
          </a>
          .
        </p>
        <p>
          Where required under the UK GDPR, in case of transfers of personal
          data to countries outside the United Kingdom, we ensure appropriate
          safeguards are in place to guarantee the continued protection of your
          personal data, particularly by signing the UK Addendum to the EU
          Standard Contractual Clauses or the UK International Data Transfer
          Agreement, whichever is more appropriate in the given situation. For
          more information on UK Addendum and the UK International Data Transfer
          Agreement please see{' '}
          <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/international-transfers/international-data-transfer-agreement-and-guidance/">
            here
          </a>
          . We may also guarantee the protection of your personal data by
          relying on adequacy decisions adopted or approved by the authorities
          in the United Kingdom.
        </p>
        <ul>
          <li>
            <strong>13. OUR POLICY REGARDING CHILDREN</strong>
          </li>
        </ul>
        <p>
          <strong>General age limitation. </strong>Loóna is not intended for or
          directed at children under 13, and we do not knowingly collect or
          solicit any information from anyone under the age of 13 or knowingly
          allow such persons to use Loóna. If you are under 13, do not: (i) use
          or provide any information in Loóna or through any of its features, or
          (ii) provide any information about yourself to us, including your
          name, address, telephone number or email address. If you are a parent
          or guardian and believe we have collected information from your child
          who is under the age of 13, please contact us at privacy@loona.app.
        </p>
        <p>
          <strong>Age limitation for EEA/UK individuals.</strong> You must be at
          least 16 years old in order to use Loóna Services. We do not allow use
          of Loóna by EEA individuals younger than 16 years old. If you are
          aware of anyone younger than 16 using Loóna, please contact us by
          e-mail: privacy@loona.app, and we will take the required steps to
          delete the information provided by such persons.
        </p>
        <ul>
          <li>
            <strong>14. ELECTRONIC COMMUNICATIONS</strong>
          </li>
        </ul>
        <p>
          By using Lóona, you also agree to receive electronic communications
          from us, including via email, push notifications and by posting
          notices on Lóona. The communications between you and us may take place
          via electronic means, whether you use Lóona or send us emails, or
          whether we post notices on Loóna or communicate with you via email.
          These communications may include notices about Lóona and the Purchased
          Content and are part of your relationship with us. For contractual
          purposes, you: (i) consent to receive communications from us in an
          electronic form; and (ii) agree that all terms and conditions,
          agreements, notices, disclosures, and other communications that we
          provide to you electronically satisfy any legal requirement that such
          communications would satisfy if it were to be in writing. The
          foregoing does not affect your statutory rights. Communications from
          us and our affiliated companies may include but are not limited to:
          operational communications concerning your Account or the use of Loóna
          (e.g., technical and security notices, updates to this Privacy Policy
          and <a href="https://loona.app/terms">Loóna Terms of Use</a>), and
          updates concerning new and existing features (e.g., changes/updates to
          features of Lóona and their scope, prices of in-app subscriptions).
        </p>
        <p>
          You may opt-out of receiving promotional emails from us at any time
          through any of the following methods:
        </p>
        <ul>
          <li>
            by following the opt-out links in any promotional email sent to you;
            or
          </li>
          <li>through Loóna settings on your mobile device; or</li>
          <li>by contacting us at any time at privacy@loona.app&nbsp;</li>
        </ul>
        <p>
          You may opt-out of push notifications by changing the settings on your
          mobile device.
        </p>
        <ul>
          <li>
            <strong>15. CONTACT US</strong>
          </li>
        </ul>
        <p>
          Please email us if you have any queries or concerns about how we use
          your personal information. We will try to resolve your query without
          undue delay.
        </p>
        <p>E-mail: privacy@loona.app</p>
        <p>
          <strong>Appointed EEA/UK representative.</strong> Loona has appointed
          BrattePL Sp. z o.o. as its data privacy representative in the EEA and
          UK to act as its direct contact for data subjects and EEA/ UK
          supervisory authorities. If you usually reside in an EEA/ UK you can
          contact BrattePL Sp. z o.o. regarding matters pertaining to the GDPR
          by:&nbsp;
        </p>
        <p>E-mail: privacy@loona.app</p>
        <p>Postal address: 8/12 Młynarska St., 01-194 Warsaw, Poland</p>
        <ul>
          <li>
            <strong>16. UPDATES</strong>
          </li>
        </ul>
        <p>
          This Privacy Policy may change from time to time, and we encourage you
          to review it periodically. The date this Privacy Policy was last
          revised is indicated at the top of the page. We may modify or update
          this Privacy Policy from time to time. Some changes do not require
          your consent. However, if we determine that the changes may pose risk
          to your rights and freedoms, we will ask for your consent to those
          changes separately from this Privacy Policy.
        </p>
        <ul>
          <li>
            <strong>17. YOUR EEA &amp; UK DATA PRIVACY RIGHTS</strong>
          </li>
        </ul>
        <p>
          This section provides further disclosures and describes the rights in
          relation to your personal data that you may have under GDPR if you are
          an individual in the EEA or UK GDPR if you are an individual in the
          UK.
        </p>
        <ol>
          <li>
            <strong>Personal data</strong>
            <strong>
              <br />
            </strong>
            “Personal data” means any information relating to an identified or
            identifiable natural person; such person is known as a ‘data
            subject’. In practice, almost all information relating to you will
            be your personal data.
          </li>
          <li>
            <strong>How do we process your information and why?</strong>
            <strong>
              <br />
            </strong>
            We will process your personal data as “controller” for the purposes
            and on the legal grounds for processing set out above. We will
            update you about any new purposes of processing your personal data
            from time to time, and we will obtain your prior consent for such
            new purposes where we are required to do so by law.
          </li>
          <li>
            <strong>Data subject rights</strong>
            <strong>
              <br />
            </strong>
            Subject to certain exemptions, limitations and appropriate proof of
            identity, as a data subject, you will generally have numerous rights
            in relation to your personal data that you may exercise with the
            controller, including the following:
          </li>
        </ol>
        <ol>
          <li>
            Right to information about matters set out in this policy. You may
            also contact us for further details about our retention policy and
            international data transfers.
          </li>
          <li>
            Right to make an access request to receive copies of personal data,
            as well as to share your personal data with other parties.
          </li>
          <li>
            Right to rectification of any inaccurate or incomplete personal
            data.
          </li>
          <li>
            Right to withdraw consent previously provided, without affecting the
            lawfulness of our processing based on consent before its withdrawal.
          </li>
          <li>
            Right to object to our processing of personal data for direct
            marketing purposes, or that is based on our legitimate interests,
            and any automated decision-making and profiling.
          </li>
          <li>
            Right to erasure of personal data, in certain circumstances. Please
            note that the erasure of some of your personal data may affect your
            ability to use Loona Services.
          </li>
          <li>
            Restriction on the processing of personal data in certain
            circumstances.
          </li>
          <li>
            Right to data portability from one service provider to another,
            where applicable. In this case, we will provide you with a copy of
            your personal data in a machine-readable format in accordance with
            industry standards so that you can pass this information to another
            data controller.
          </li>
          <li>
            Right to lodge a complaint with your country’s supervisory
            authority. Subject to the GDPR, you have the right to lodge a
            complaint with a local data protection authority in the country of
            your residence, where you work or where an alleged infringement of
            the applicable data protection law took place. Please see a list of
            EU member states’ supervisory authority{' '}
            <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">
              here
            </a>
            , and the UK’s supervisory authority (ICO){' '}
            <a href="https://ico.org.uk/make-a-complaint/">here</a>.
          </li>
        </ol>
        <p>
          All requests will be processed in a timely manner, generally within
          one month. If we cannot process your request within this period, we
          will explain why and extend the period by up to two months where
          necessary, taking into account the complexity and number of requests.
          You can exercise these rights directly with us by submitting a request
          at privacy@loona.app. Please note that these rights are not absolute
          and we may be entitled (or required) to refuse requests where
          exceptions apply.
        </p>
        <ol>
          <li>
            <strong>18. YOUR CALIFORNIA PRIVACY RIGHTS</strong>
          </li>
        </ol>
        <p>
          ​​California’s “Shine the Light” law (Civil Code Section § 1798.83)
          permits users of our Website that are California residents to request
          certain information regarding our disclosure of personal information
          to third parties for their direct marketing purposes. To make such a
          request, please send an email to privacy@loona.app.
        </p>
        <ul>
          <li>
            <strong>19. YOUR NEVADA DATA PRIVACY RIGHTS</strong>
          </li>
        </ul>
        <p>
          Nevada residents who wish to exercise their sale opt-out rights under
          Nevada Revised Statutes Chapter 603A may submit a request to
          privacy@loona.app. However, please know we do not currently sell data
          triggering that statute’s opt-out requirements.
        </p>
      </>
    </PageWrapper>
  );
};
