import { useEffect, useState } from 'react';
import { getOneLink } from '../../utils';

export const useBannerLogic = () => {
  const [isBannerShown, setBannerShown] = useState<boolean>(false);

  useEffect(() => {
    const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
    const isBannerShownBefore = localStorage.getItem('shownBanner') === 'true';

    if (isMobileDevice && !isBannerShownBefore) {
      setBannerShown(true);
    }
  }, []);

  const onClose = () => {
    setBannerShown(false);
    localStorage.setItem('shownBanner', 'true');
  };

  const onClickHandler = () => {
    const link = getOneLink({
      mediaSource: 'website',
      campaign: 'smartbanner',
      utm_campaign: window.location.href,
      retargeting: true,
    });
    window.location.href = link;
    onClose();
  };

  return {
    isBannerShown,
    isIosDevice: !!navigator.platform.match(/iPhone|iPod|iPad/),
    onClose,
    onClickHandler,
  };
};
