import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Template } from '../template/Template';

export const ExpiredHash = () => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(push('/reset-password'));
  };

  return (
    <Template
      title={
        <span>
          <span className="accent">Oops..</span>
          <br />
          The link has expired
        </span>
      }
      buttonDisabled={false}
      buttonLabel="Request a new link"
      isLoading={false}
      handleSubmit={handleSubmit}
    />
  );
};
