import React from 'react';
import { Link } from 'react-router-dom';
import { GooglePlayButton } from './GooglePlayButton';
import { AppStoreButton } from './AppStoreButton';
import styles from './styles.module.scss';

export const Footer = () => (
  <footer>
    <div className={styles.navigation}>
      <div className={styles.product}>
        <div className={styles.title}>Product</div>
        <ul>
          <li>
            <a href="https://www.dropbox.com/sh/lz88993j15uixox/AADqMXHAglornjLHeLgP9AgWa?dl=0">
              Press kit
            </a>
          </li>
          <li>
            <a href="https://www.notion.so/loonaapp/Work-at-Lo-na-fa2a632a634b4bf5967843897e3d09ec">
              Careers
            </a>
          </li>
          <li>
            <a href="https://loona.app/gift">Gifts</a>
          </li>
          <li>
            <a href="https://www.quiz.loona.app">Quizzes</a>
          </li>
          <li>
            <a href="mailto:hello@loona.app">Contact us</a>
          </li>
        </ul>
      </div>
      <div className={styles.app}>
        <div className={styles.title}>Get the app</div>
        <ul>
          <li>
            <GooglePlayButton />
          </li>
          <li>
            <AppStoreButton />
          </li>
        </ul>
      </div>
      <div className={styles.terms}>
        <div className={styles.title}>Terms</div>
        <ul>
          <li>
            <Link to="/terms">Terms of use</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy policy</Link>
          </li>
          <li>
            <Link to="/cookie-policy">Cookie policy</Link>
          </li>
          <li>
            <Link to="/web-accessibility-statement">
              Accessibility {'\n'} Statement
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.socialMedia}>
        <div className={styles.title}>Social Media</div>
        <ul>
          <li>
            <a href="https://www.instagram.com/loona_app/">Instagram</a>
          </li>
          <li>
            <a href="https://www.facebook.com/groups/loonaofficial">Facebook</a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@loona_app">TikTok</a>
          </li>
          <li>
            <a href="https://twitter.com/loona_app">Twitter</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/lo%C3%B3na/">Linkedln</a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCvOwxjPkjT8TdGrEP3IsxzA">
              Youtube
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);
