import { RedeemActionTypes } from './index';

export const SET_CODE_PREFETCH = 'SET_CODE_PREFETCH';

export interface SetCodePrefetch {
  type: typeof SET_CODE_PREFETCH;
  payload: {
    code: string;
    error?: string;
  };
}

export function setCodePrefetch(
  code: string,
  error?: string,
): RedeemActionTypes {
  return {
    type: SET_CODE_PREFETCH,
    payload: {
      code: code,
      error: error,
    },
  };
}
