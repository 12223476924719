import { useLocation } from 'react-router-dom';
import translationsEn from './resources/en.json';
import translationsRu from './resources/ru.json';
import { Translation } from './translation';

export function useTranslation(): Translation {
  const location = useLocation();
  const file = location.pathname.startsWith('/ru')
    ? translationsRu
    : translationsEn;
  return new Translation(file);
}
