import { Event } from '../../analytics/Event';
import { Events } from '../../analytics/Events';

export class Install extends Event {
  constructor() {
    super(Events.Install);
  }
}

export class SignUp extends Event {
  constructor() {
    super(Events.SignUp);
  }
}
