import React, { useEffect } from 'react';
import { ReactComponent as Logo } from '../../assets/rocket10/logo.svg';
import { Letter } from './components/letter/Letter';
import { Advantages } from './components/adventages/Adventages';
import { advantages } from './constants';
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';
import { WhyLoona } from './components/whyloona/WhyLoona';
import { useAppDispatch } from '../../bootstrap/hooks';
import { push } from 'connected-react-router';
import HeaderImg from '../../assets/rocket10/header_img.png';
import HeaderImgMobile from '../../assets/rocket10/header_img_mobile.png';
import GiftCard from '../../assets/rocket10/gift_card.png';
import { MOBILE_QUERY, useMediaQuery } from '../../hooks/useMediaQuery';
import { ROCKET10 } from '../redeem/code/RedeemCode.constants';
import { updateFavicons } from './utils';
import styles from './styles.module.scss';

export const Rocket10 = () => {
  const dispatch = useAppDispatch();
  const isLargeScreen = useMediaQuery(MOBILE_QUERY);

  useEffect(() => {
    updateFavicons('rocket10');
  }, []);

  const handleClick = () => {
    dispatch(push(`b2b/redeem/account?c=${ROCKET10}`));
  };

  const headerImage = isLargeScreen ? HeaderImg : HeaderImgMobile;
  const companyName = (
    <>
      Rocket
      <span>10</span>
    </>
  );

  return (
    <div className={`${styles.wrapper} ${styles.redTheme}`}>
      <Header
        logo={<Logo />}
        headerImage={headerImage}
        theme="red"
        handleClick={handleClick}
      />
      <Letter logo={<Logo />} theme="red" companyName={companyName} />
      <WhyLoona theme="red" companyName={companyName} />
      <Advantages adventages={advantages} theme="red" />
      <Footer
        theme="red"
        giftCard={GiftCard}
        companyName={companyName}
        handleClick={handleClick}
      />
    </div>
  );
};
