import { Events } from './Events';
import { Event } from './Event';

export class Analytics {
  supportedEvents: Events[] = Object.values(Events);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackEvent(event: Event) {
    throw new Error('trackEvent should be implemented');
  }
}
