import React from 'react';
import ReactFacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import { appAnalytics } from '../../../../analytics';
import { useAppDispatch } from '../../../../bootstrap/hooks';
import { ReactComponent as FacebookLogo } from '../../../../assets/ic_facebook.svg';
import { socialLogin } from '../../redux/actions/register';
import { useTranslation } from './../../../bidease/i18n/useTranslation';
import { RedeemSignUp } from '../../analytics';
import styles from './styles.module.scss';

const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID || '';

type Props = {
  onError: (type: 'facebook', error: string) => void;
};

export const FacebookLoginButton = ({ onError }: Props) => {
  const dispatch = useAppDispatch();
  const translations = useTranslation();

  const handleFacebookLogin = (
    response: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
  ) => {
    const fbLoginInfo = response as any;
    if (fbLoginInfo.id) {
      dispatch(
        socialLogin(
          fbLoginInfo.first_name || '',
          fbLoginInfo.last_name || '',
          fbLoginInfo.email || '',
          fbLoginInfo.id,
          fbLoginInfo.accessToken,
          'facebook',
        ),
      )
        .then(() => {
          appAnalytics.trackEvent(new RedeemSignUp('facebook'));
        })
        .catch((error) => {
          onError('facebook', error);
        });
    }
  };

  return (
    <ReactFacebookLogin
      appId={FACEBOOK_CLIENT_ID}
      fields="id,name,first_name,last_name,email"
      disableMobileRedirect={true}
      cssClass={styles.socialLoginButton}
      textButton={translations.get('facebook_login_button')}
      icon={<FacebookLogo />}
      callback={handleFacebookLogin}
    />
  );
};
