import './styles.scss';
import React, { useState } from 'react';

import { ReactComponent as ClosedEye } from '../../assets/icon_eye_closed.svg';
import { ReactComponent as OpenedEye } from '../../assets/icon_eye_opened.svg';

type Props = {
  value: string;
  placeholder?: string;
  isValid: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocusChange?: (isFocused: boolean) => void;
};

export const PasswordInput = ({
  value,
  placeholder = 'Password',
  isValid,
  onChange,
  onFocusChange,
}: Props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div className="password-container">
      <input
        type={isPasswordVisible ? 'text' : 'password'}
        className={!isValid ? 'invalid' : ''}
        value={value}
        placeholder={placeholder}
        onFocus={() => onFocusChange?.(true)}
        onBlur={() => onFocusChange?.(true)}
        onChange={onChange}
      />
      {isPasswordVisible ? (
        <OpenedEye
          className="eye opened"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        />
      ) : (
        <ClosedEye
          className="eye closed"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        />
      )}
    </div>
  );
};
