const LINK_PARAMS_MAP: Record<string, string> = {
  mediaSource: 'pid',
  webDp: 'af_web_dp',
  campaign: 'c',
  retargeting: 'is_retargeting',
  iosUrl: 'af_ios_url',
  androidUrl: 'af_android_url',
  utmSource: 'utm_source',
  utmMedium: 'utm_medium',
  utmCampaign: 'utm_campaign',
  utmContent: 'utm_content',
  utm_source: 'c',
  utm_medium: 'af_adset',
  utm_campaign: 'af_ad',
};

export const getOneLink = (
  params: Object,
  authToken?: string,
  oneLink?: string,
) => {
  const link = oneLink || 'https://loona.onelink.me/Jfkp';

  const url = new URL(link);
  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(LINK_PARAMS_MAP[key], value);
  }

  const currentParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );

  Object.keys(currentParams)
    .filter((key) => LINK_PARAMS_MAP[key])
    .forEach((key) => {
      url.searchParams.append(LINK_PARAMS_MAP[key], currentParams[key]);
    });

  authToken && url.searchParams.append('deep_link_value', authToken);

  return url.href;
};

export const getLink = (link: string, params: Object) => {
  const url = new URL(link);
  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(LINK_PARAMS_MAP[key], value);
  }

  return url.href;
};

export const isTouchDevice = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
};
