import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as ArrowUpCircle } from '../../../../assets/loonaWeb/arrow_up.svg';
import { ReactComponent as ArrowDownCircle } from '../../../../assets/loonaWeb/arrow_down.svg';

type Props = {
  question: string;
  answer: string | JSX.Element;
};

export const Item = ({ question, answer }: Props) => {
  const [active, setActive] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef && contentRef.current) {
      if (active) {
        setMaxHeight(contentRef.current.scrollHeight);
      } else {
        setMaxHeight(0);
      }
    }
  }, [contentRef, active]);

  const toggleAccordionItem = (event: any) => {
    if (!(event.target instanceof HTMLAnchorElement)) {
      setActive(!active);
    }
  };

  return (
    <div className={styles.item} onClick={toggleAccordionItem}>
      <div className={styles.questionContent}>
        <div className={styles.question}>{question}</div>
        {active ? <ArrowUpCircle /> : <ArrowDownCircle />}
      </div>
      <div ref={contentRef} className={styles.answer} style={{ maxHeight }}>
        <p>{answer}</p>
      </div>
    </div>
  );
};
