import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { appAnalytics } from '../../../../analytics';
import { RootState } from '../../../../bootstrap/reducer';
import { getQueryVariable } from '../../../../utils';
import { RedeemGiftCodeActivated } from '../../analytics';
import { checkSubscription, login, register, socialRegister } from '../../net';
import { SET_CODE_PREFETCH } from './code';

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';

export interface SetAuthToken {
  type: typeof SET_AUTH_TOKEN;
  token: string;
}

export interface SetSubscription {
  type: typeof SET_SUBSCRIPTION;
  payload: {
    hasSubscription: boolean;
    oldUser: boolean;
  };
}

export const emailRegister =
  (
    email: string,
    password: string,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    return register(email, password)
      .then((userInfo) => {
        dispatch({
          type: SET_AUTH_TOKEN,
          token: userInfo.auth_token,
        });
        return dispatch(checkActiveSubscription());
      })
      .catch((error) => {
        let message;
        if (error.description === 'DATA_EXIST') {
          return dispatch(emailLogin(email, password));
        } else {
          message = error.debug_text;
        }
        return Promise.reject(message);
      });
  };

export const emailLogin =
  (
    email: string,
    password: string,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    return login(email, password)
      .then((userInfo) => {
        dispatch({
          type: SET_AUTH_TOKEN,
          token: userInfo.auth_token,
        });
        return dispatch(checkActiveSubscription());
      })
      .catch((error) => {
        const message = error.debug_text;
        return Promise.reject(message);
      });
  };

export const socialLogin =
  (
    firstName: string,
    lastName: string,
    email: string,
    id: string,
    accessToken: string,
    type: 'facebook',
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    return socialRegister(firstName, lastName, email, id, accessToken, type)
      .then((userInfo) => {
        dispatch({
          type: SET_AUTH_TOKEN,
          token: userInfo.auth_token,
        });
        return dispatch(checkActiveSubscription());
      })
      .catch((error) => {
        const message = error.debug_text || error.message;
        return Promise.reject(message);
      });
  };

const checkActiveSubscription =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    return checkSubscription()
      .then((isActive) => {
        dispatch({
          type: SET_SUBSCRIPTION,
          payload: {
            hasSubscription: isActive,
            oldUser: isActive,
          },
        });
        const code = getQueryVariable('c');
        if (code && code !== '') {
          dispatch({
            type: SET_CODE_PREFETCH,
            payload: { code: code },
          });
          appAnalytics.trackEvent(new RedeemGiftCodeActivated(code));
        }
        dispatch(push(`code?c=${code}`));
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error.debug_text);
      });
  };
