import React from 'react';
import style from './styles.module.scss';
import { Title } from '../title/Title';
import { Item } from './item/Item';

type Item = {
  question: string;
  answer: string | JSX.Element;
};

const faq: Item[] = [
  {
    question: 'What is Loóna?',
    answer:
      'Loóna is an award-winning app that serves as a healthy wind-down ritual to put people’s minds to bed. Loóna is known for its enchanting and immersive experiences called escapes.',
  },
  {
    question: 'What is an Escape?',
    answer:
      "The app’s number one feature loved by users incorporates science-based relaxation techniques with innovative motion graphics, cultivating a spectacular rotating scene to color in. Combined with soothing narrative tales, escapes help users disengage from anxious thoughts and get in the right mood for a restful night's sleep.",
  },
  {
    question: 'Where is Loóna available?',
    answer:
      'Loóna is available globally for download on iOS at the App Store and Android at Google Play Market.',
  },
  {
    question: 'Can I buy a gift card?',
    answer: (
      <>
        Give the gift of sleep! Loóna gift cards are available for purchase at{' '}
        <a href="https://loona.app/gift" target="_blank" rel="noreferrer">
          https://loona.app/gift
        </a>
      </>
    ),
  },
];

export const FaqAccordion = () => {
  return (
    <div className={style.container}>
      <Title className={style.title}>
        <span>FAQ: Fairies</span> Answering Questions
      </Title>
      {faq.map(({ question, answer }, index) => {
        return <Item question={question} answer={answer} key={index} />;
      })}
    </div>
  );
};
