import React from 'react';
import { PageWrapper } from '../components/pagewrapper/PageWrapper';

export const CaliforniaPrivacy = () => {
  return (
    <PageWrapper>
      <h1>California Data Privacy Notice for California Residents</h1>
      <h2>
        <span>The California Consumer Privacy Act (“CCPA”)</span>
      </h2>
      <p>
        <span>
          California residents may have additional privacy rights under
          applicable California law. A “California resident” is a natural person
          who resides in California, even if the person is temporarily outside
          of the state.
        </span>
      </p>
      <p>
        <span>
          Specifically, the California Consumer Privacy Act (“CCPA”) provides
          California residents additional rights to (i) know the personal
          information collected about them, (ii) request deletion of such
          information (subject to certain exceptions), (iii) opt-out of the sale
          of such information, and (iv) not be discriminated against for
          exercising those rights. Business that are subject to the CCPA are
          required to disclose the means to exercise the foregoing rights.
        </span>
      </p>
      <p>
        <span>
          Loona uses and discloses personal information for business purposes
          only as set forth in its Privacy Policy. All collection, use and
          disclosure of personal information is described in Loona’s Privacy
          Policy in the section entitled,
          <b>“How We Use Your Information”</b> and{' '}
          <b>“How We May Disclose Your Information to Third Parties”. </b>
          The categories of personal information collected and disclosed to
          third parties for business purposes by us are listed in the sections
          entitled, <b>“Information and Data we Collect from you”</b> and{' '}
          <b>“Other Information”.</b>
        </span>
      </p>
      <p>
        <span>
          Loona does not sell information as the term “sell” is traditionally
          understood. Notwithstanding, to the extent Loona’s handling of
          personal information in accordance with its Privacy Policy is
          interpreted as a “sale” under the CCPA, Loona will comply with
          applicable law as to such activity.
        </span>
      </p>
      <p>
        <span>
          If you are a California resident using the Services, you have the
          following rights:
        </span>
      </p>
      <ul>
        <li>
          <span>
            To request the categories of personal information that the business
            collected about you or disclosed about you for a business purpose.
          </span>
        </li>
        <li>
          <span>
            To request deletion of the personal information it has collected
            from you, subject to certain legal exceptions.
          </span>
        </li>
        <li>
          <span>
            To be protected from discrimination for exercising your CCPA rights.
          </span>
        </li>
        <li>
          <span>To opt-out of the sale of your personal information.</span>
        </li>
      </ul>
      <p>
        <span>
          Your rights under CCPA may be limited as permitted by applicable law
          (for example, if you are using the Services as an employee or agent
          under a business account). Loona expressly reserves all rights to
          claim legal exemptions permitted under the CCPA.
        </span>
      </p>
      <p>
        <span>
          If you have a question about Loona’s practices under the CCPA or to
          the extent you wish to exercise your rights thereunder, you may
          contact us at:{' '}
          <a href="mailto:privacy@loona.app"> privacy@loona.app</a>. In the
          request, please specify which right you are seeking to exercise and
          the scope of the request. Loona reserves the right to request
          additional information from you to help us verify your identity and
          process your request.
        </span>
      </p>
      <h2>
        <span>California’s “Shine the Light” Law</span>
      </h2>
      <p>
        <span>
          California residents may annually request and obtain information that
          Loona shared with other businesses for their own direct marketing use
          within the prior calendar year.
        </span>
      </p>
      <p>
        <span>
          California users may request further information about our compliance
          with this law by emailing us at{' '}
          <a href="mailto:privacy@loona.app">privacy@loona.app</a>.
        </span>
      </p>
      <h2>
        <span>Do Not Track Signals</span>
      </h2>
      <p>
        <span>
          Loona does not respond to Do Not Track (DNT) signals. However, some
          third-party sites do keep track of your browsing activities when they
          serve you content, which enables them to tailor what they present to
          you. If you are visiting such sites, your browser may include controls
          to block and delete cookies, web beacons and similar technologies, to
          allow you to opt out of data collection through those technologies.
        </span>
      </p>
    </PageWrapper>
  );
};
