import React from 'react';
import { isTouchDevice } from '../../utils';
import { Logos } from './Ticket.constants';
import styles from './styles.module.scss';

export const Ticker = () => {
  const logoItems = Logos.map(({ url, image }, index) => (
    <a href={url} key={index} target="_blank" rel="noreferrer">
      <img src={image} alt="" />
    </a>
  ));
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div
          className={`${styles.content} ${
            !isTouchDevice() ? styles.hoverable : ''
          }`}
        >
          {logoItems}
          {logoItems}
        </div>
      </div>
    </div>
  );
};
