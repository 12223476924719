import React from 'react';
import { Button } from '../button/Button';
import { Title } from '../title/Title';
import { Description } from '../description/Description';
import styles from './styles.module.scss';
import { useTranslation } from '../../i18n/useTranslation';

type Props = {
  giftCard: string;
  theme?: string;
  companyName: string | JSX.Element;
  handleClick: () => void;
};

export const Footer = ({
  companyName,
  theme,
  giftCard,
  handleClick,
}: Props) => {
  const translations = useTranslation();

  return (
    <footer className={`${styles.footer} ${theme ? styles[theme] : ''}`}>
      <div className={styles.contentWrapper}>
        <Title>
          <div className={styles.title}>
            {translations.get('footer_title')}{' '}
            <span className={styles.accent}>{companyName}</span>
          </div>
        </Title>
        <Description>
          <div className={styles.description}>
            {translations.get('footer_description')}
          </div>
        </Description>
        <Button className={styles.button} onClick={handleClick}>
          {translations.get('gift_button')}
        </Button>
      </div>
      <img className={styles.image} src={giftCard} alt="" />
    </footer>
  );
};
