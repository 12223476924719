import React from 'react';
import './styles.scss';

export const Loader = () => (
  <>
    <span className="loader-dot" />
    <span className="loader-dot" />
    <span className="loader-dot" />
  </>
);
